@import 'app/styles/config';
@import 'libs/styles/responsive';
@import 'libs/styles/fonts';

.products {
  .products__icons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.25rem;

    @include target('xs', 'md') {
      margin-top: 2rem;
      flex-direction: column;
      // gap: 3rem;
    }

    .products__icon {
      width: 100%;
      display: flex;
      gap: 1.25rem;

      .products__icon__text {
        width: 100%;
        display: flex;
        flex-direction: column;

        font-size: 1.25rem;
        font-weight: $fw-medium;
        line-height: 1.625rem;

        span {
          color: $color-black;
        }

        p {
          color: $color-grey;
          margin-bottom: 1rem;
        }

        a {
          font-size: 1rem;
        }

        @include target('xs', 'md') {
          font-size: 1rem;
          line-height: 1.25rem;
          padding-top: 0.25rem;

          p {
            margin-bottom: 0.5rem;
          }
        }
      }

      .products__icon__image {
        flex-shrink: 0;
        width: 6rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 50%;

        @include target('xs', 'md') {
          width: 3rem;
          height: 3rem;
          padding: 0.5rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .products__icon__image--pink {
        background-color: $color-green-dim;
      }

      .products__icon__image--green {
        background-color: $color-blue-dim;
      }

      .products__icon__image--blue-light {
        background-color: $color-red-dim;
      }
    }
  }
}