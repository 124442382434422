@import 'app/styles/config';
@import 'libs/styles/responsive';

.button {
  display: block;
  border: none;
  font: inherit;
  font-family: $font-SuisseIntl;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
  background-color: transparent;
  color: $color-black;

  &.button--medium {
    font-size: 14px;
    line-height: 1.29;
    padding: 1rem 1.375rem;
  }

  &.button--wide {
    line-height: 1.29;
    font-size: 16px;
    padding: 1rem 3.25rem;
  }

  &.button--inline-block {
    display: inline-block;
  }

  &.button--black {
    background-color: $color-black;
    color: $color-white;
    transition: background-color 0.25s ease;

    &:hover {
      background-color: $color-black-hover;
    }
  }

  &.button--white {
    background-color: $color-white;
    color: $color-black;
  }

  &.button--light-green {
    background-color: $color-green-light;
    color: $color-black;
  }

  &.button--rounded {
    border-radius: 100px;
  }

  &.button--square {
    min-height: 3.5rem;
    font-size: 16px;
    line-height: 1.5;
    width: 100%;
    text-align: center;
  }

  &.button--icon {
    border-radius: 30px;
    max-height: 3.75rem;
    max-width: 3.75rem;
  }

  &.button--secondary {
    background-color: $color-grey-lighter;
    color: $color-black;
    transition: background-color 0.25s ease;

    &:hover {
      background-color: $color-grey-light;
    }
  }

  &.button--gray {
    background-color: $color-grey-light;
    color: $color-black;
    transition: background-color 0.25s ease;

    &:hover {
      background-color: $color-grey-lighter;
    }
  }

  &.button--disabled {
    background-color: $color-grey-lighter;
    color: $color-black;
    transition: background-color 0.25s ease;

    &:hover {
      background-color: $color-grey-light;
    }
  }

  @include target('xl', 'xxl') {
    &.button--square {
      width: fit-content;
      height: fit-content;
    }
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: flex-start;
  gap: 1rem;

  .button--square {
    width: auto;
  }

  &.button-container--mt {
    margin-top: 30px;
  }
}

.button-list {
  display: flex;
  gap: 0.75rem;

  @include target('xs', 'sm') {
    overflow-x: auto;
    min-width: calc(100vw - 1.25rem);

    padding-bottom: 1rem;
    position: relative;
    inset: 0;
    z-index: 100;
    padding-right: 1.25rem;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .button-list__button {
    transition: all 0.3s ease-out;
    appearance: none;
    outline: none;
    border: none;
    width: max-content;
    padding: 1rem 1.75rem;
    border-radius: 2.625rem;
    background-color: $color-grey-lighter;
    color: $color-grey-dark;
    font-size: 0.875rem;
    cursor: pointer;
    white-space: nowrap;
  }

  .button-list__button--active {
    background-color: $color-black;
    color: $color-white;
  }
}