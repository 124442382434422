@import 'app/styles/config';
@import 'libs/styles/responsive';
@import 'libs/styles/fonts';
@import 'app/common/list/list';

.accordion {
  .questions {
    display: flex;
    flex-direction: row;
    padding-bottom: 1.75rem;

    border-top: 1px solid $color-black;

    font-weight: $fw-medium;
    font-size: 1.25rem;
    line-height: 1.3;

    @include target('xs', 'md') {
      font-size: 1rem;
      line-height: 1.25;
    }

    @include target('xs', 'sm') {
      padding-bottom: 1.25rem;
    }
  }

  .questions-number {
    width: 2rem;
    margin-right: 0.875rem;
    text-align: left;
    margin-top: 0.25rem;
  }

  .question-section {
    display: flex;
    flex-direction: column;
    width: calc(100% - 2.875rem);
  }

  .question-section-body {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include target('xs', 'sm') {
      margin-top: 0.75rem;
      gap: 1.5rem;
    }
  }

  .question__answer {
    color: $color-grey;
    cursor: default;

    a {
      text-decoration: none;
      color: $color-green-dark;

      &:hover {
        color: $color-green-dark-hover;
      }
    }

    *,
    ol li::marker {
      font-family: $font-SuisseIntl;
      font-size: 16px;
      color: $color-grey;
      font-weight: $fw-medium;

      @include target(xl, xxl) {
        font-size: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      padding-left: 0;
      @extend .list;
      @extend .list--outlined;

      li {
        @extend .list__item;

        &::before {
          filter: invert(1);
        }
      }

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }

    p:not(:last-child) {
      margin-bottom: 6px;

      @include target(xl, xxl) {
        margin-bottom: 12px;
      }
    }
  }

  .question__title {
    cursor: default;
  }

  .question-section-title {
    display: flex;
    flex-direction: row;
    align-items: self-end;
    cursor: pointer;

    .questions-title__heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: 100%;
      margin-top: 0.25rem;
    }
  }

  .questions-title__icon {
    @include target(xs) {
      margin-top: -5px;
    }
  }

  .questions-title__icon--up {
    transform: rotate(180deg);
  }

  .questions-title__icon--down {
    transform: rotate(0deg);
  }

  .inline {
    display: inline-block;
  }

  &--nolist {
    .questions-number {
      display: none;
    }

    .question-section {
      width: 100%;
    }
  }

  &--nolist--mobile {
    @include target('xs') {
      .questions-number {
        display: none;
      }

      .question-section {
        width: 100%;
      }
    }
  }
}