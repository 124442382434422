@use 'config';
@use 'libs/styles/responsive';

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.lightbox__content {
    display: flex;
    position: relative;
    background-color: config.$color-white;
    max-width: 92%;
    max-height: 92%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    overflow: auto;

    @include responsive.target(md, xxl) {
        max-width: 80%;
        max-height: 80%;
    }
}

.lightbox__close-button {
    position: absolute;
    top: 2.6vw;
    right: 4.5vw;
    padding: 0;
    background: transparent;
    border-radius: 18px;
    border: 0;
    width: 36px;
    height: 36px;
    font-size: 32px;
    cursor: pointer;
    background: url('/static/media/close_icon.svg') 0 0 no-repeat;
    background-size: contain;
}

.lightbox__media {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: 0 auto;

    &.lightbox__media--image {
        padding: 10px;
    }
}