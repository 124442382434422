@import 'libs/styles/fonts';
@import 'libs/styles/responsive';


.blog__container {
  display: grid;
  gap: 1.25rem;
  width: 100%;
  max-width: 100vw;

  &.blog__container--inside-article {
    @include target('xl', 'xxl') {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 3.875rem;
    }
  }

  &.blog__container--padding {
    @include target('xs') {
      padding: 0;
    }

    @include target('sm', 'lg') {
      padding: 0 1.25rem;
    }
  }

  @include target('xs') {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1.625rem;

    &.blog__container--main-article {
      margin-bottom: 1.625rem;
    }
  }

  @include target('sm', 'lg') {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2.5rem;

    &.blog__container--main-article {
      margin-bottom: 2.5rem;
    }
  }

  @include target('xl', 'xxl') {
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 5rem;

    &.blog__container--main-article {
      margin-bottom: 5rem;
    }
  }

  a {
    text-decoration: none;
  }

  .article__span {
    @include target('sm', 'xxl') {
      &.article__span--large {
        grid-column: span 2;
      }

      &:nth-of-type(1),
      &:nth-of-type(6),
      &:nth-of-type(9) {
        grid-column: span 2;
      }
    }
  }

  .article__main {
    @include target('sm', 'lg') {
      grid-column: span 2;
    }

    @include target('xl', 'xxl') {
      grid-column: span 3;

      .title {
        font-size: 3rem;
      }
    }
  }

  .blog-article-overview {
    @include target('xs') {
      padding-bottom: 0.875rem;
    }
  }

  .article__box {
    display: flex;
    flex-direction: column;

    @include target('xs') {
      max-width: calc(100vw - 2.5rem);
    }
  }

  a {
    text-decoration: none;
  }

  a:hover .title {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
    text-decoration-skip-ink: none;
  }

  .blog-articles-count {
    display: none;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: $fw-medium;
    line-height: 1.25;
    margin-top: 100px;

    @include target('xs', 'lg') {
      display: block;
    }
  }

  .banner {
    position: relative;
    width: 100%;
    aspect-ratio: 1.93;
    flex-shrink: 0;
    overflow: hidden;

    @include target('xs') {
      aspect-ratio: 1.54;
    }

    img {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .metadata {
    margin-top: 7px;

    @include target(xl, xxl) {
      margin-top: 14px;
    }

    .author,
    .date {
      display: inline;
      color: $color-grey-dark;
      font-size: 14px;
      font-style: normal;
      font-weight: $fw-medium;
      line-height: 1.28;
    }

    .author::after {
      content: ' | ';
    }
  }

  .title {
    margin-top: 7px;

    @include target(xl, xxl) {
      margin-top: 14px;
    }

    color: $color-black;
    font-family: $font-Tiempos;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: $fw-medium;
    line-height: 1.08em;
    width: 100%;
  }

  .teaser {
    color: $color-black;
    font-family: $font-Tiempos;
    font-size: 18px;
    font-style: normal;
    font-weight: $fw-regular;
    line-height: 1.22;
    margin-top: 7px;

    @include target(xl, xxl) {
      margin-top: 14px;
    }

    @include target('xs', 'sm') {
      font-size: 16px;
    }
  }
}


.blog__tags {
  margin-top: 60px;
  margin-bottom: 1rem;

  .blog__articles-count {
    color: $color-black;
    font-size: 20px;
    font-style: normal;
    font-weight: $fw-medium;
    line-height: 1.3;
  }

  .tags {
    margin-top: 0.25rem;
  }

  @include target(xs, lg) {
    margin-top: 30px;
    margin-bottom: 0.5rem;
  }
}