@import 'config';
@import './fonts';

@function breakpoint-next($name,
  $breakpoints: $breakpoints,
  $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min !=0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 0.02px, null);
}

@mixin target($from, $to: $from) {
  $min: breakpoint-min($from, $breakpoints);
  $max: breakpoint-max($to, $breakpoints);

  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }

  @else if $max==null and $min !=null {
    @media (min-width: $min) {
      @content;
    }
  }

  @else if $min==null and $max !=null {
    @media (max-width: $max) {
      @content;
    }
  }

  @else {
    @content;
  }
}

@mixin hide-on-target($from, $to: $from) {
  @include target($from, $to) {
    display: none;
  }
}

@mixin show-on-target($from, $to: $from, $display: block) {
  @include target($from, $to) {
    display: $display;
  }
}

.responsive-debug {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 5px 10px;
  background: #3200ff;
  color: $color-white;
  opacity: 0.8;
  font-size: 12px;
  font-weight: $fw-medium;
  z-index: 9999;

  &:before {

    @each $name,
    $width in $breakpoints {
      @include target($name) {
        content: 'Target: #{$name}';
      }
    }
  }
}
