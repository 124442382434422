@import 'libs/styles/fonts';
@import 'app/styles/config';
@import 'libs/styles/responsive';

.member-card {
  .member-card__image {
    display: block;
    width: 100%;
    // height: 13.5625rem;
    aspect-ratio: 2 / 2.5;
    object-fit: cover;

    &[src=''] {
      background-color: $color-grey-lighter;
    }
  }

  .member-card__body {
    margin-top: 0.875rem;
  }

  .member-card__name {
    font-size: 20px;
    line-height: 1.3;
    font-weight: $fw-medium;
  }

  .member-card__socials {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin: 8px 0;

    a {
      text-decoration: none;
      transition: filter 0.25s ease;

      &:hover {
        filter: invert(41%) sepia(0%) saturate(8%) hue-rotate(184deg) brightness(90%) contrast(91%);
      }
    }
  }

  .member-card__title {
    line-height: 1.25;
    font-weight: $fw-medium;
  }

  .member-card__text {
    line-height: 1.25;
    margin-top: 8px;
  }

  .member-card__link {
    margin-top: 8px;
  }

  @include target('xl', 'xxl') {
    .member-card__image {
      width: 100%;
      height: 21.5rem;
    }

    .member-card__link {
      margin-top: 1rem;
    }

    .member-card__body {
      margin-top: 16px;
    }

    .member-card__name {
      font-size: 36px;
      line-height: 1.15;
    }
  }
}