@import 'app/styles/config';
@import 'libs/styles/fonts';

textarea {
  border-radius: 0;
}

.textarea {
  width: 100%;
  padding: 1.25rem 0.75rem;
  box-sizing: border-box;
  border-left: 3px solid $color-black;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
  resize: none;
  background-color: $color-grey-lighter;
  font-size: 1rem;
  font-family: $font-SuisseIntl;
  font-weight: $fw-regular;

  &:focus {
    outline: none;
    background-color: $color-white;
    box-shadow: inset 0px -1px 0px 0px $color-black;
    color: $color-black !important;
    border-color: $color-black !important;
  }

  &::placeholder {
    font-size: 16px;
    font-weight: $fw-regular;
    line-height: 1.25;
    font-family: $font-SuisseIntl;
    opacity: 1;
    color: $color-grey;
  }

  &.textarea--dark {
    color: $color-white;
    background-color: $color-black;
    border-left: 3px solid $color-white;
    border-bottom: 1px solid $color-white;
  }

  &.textarea--error {
    background-color: $color-red-light !important;
    border-left: 3px solid $color-red;
  }

  &.error {
    background-color: $color-red-light;
    border-color: $color-red;
  }
}
