@use 'libs/styles/responsive';
@use 'app/styles/config';


.cta-banner {
  position: relative;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 30px;
  margin-bottom: 1rem;

  @include responsive.target(md, xxl) {
    padding: 60px;
  }

  .text-plugin-content+& {
    margin-top: 1rem;
  }
}

.cta-banner__headline {
  color: config.$color-white;
  font-weight: 500;
  margin-bottom: 20px;
  font-family: config.$font-SuisseIntl;
  font-size: 22px;
  line-height: 1.21;

  @include responsive.target(md, xl) {
    font-size: 32px;
  }

  @include responsive.target(xxl) {
    font-size: 40px;
  }
}

.cta-banner__button {
  display: inline-block;
  padding: 18px 20px;
  outline: none;
  border: none;
  background-color: config.$color-white;
  color: config.$color-black;
  font-size: 16px;
  font-weight: 500;
  font-family: config.$font-SuisseIntl;
  transition: color 0.2s ease;

  &:hover {
    color: config.$color-grey;
  }
}