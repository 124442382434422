@import 'libs/styles/responsive';
@import 'libs/styles/fonts';

.book-button-wrap {
    display: flex;
    position: fixed;
    bottom: 32px;
    right: 32px;
    min-height: 3.75rem;
    z-index: 999;

    @include target(xl, xxl) {
        min-height: unset;
    }

    .calculator-body & {
        display: none;
    }
}

.book-button {
    margin-left: auto;
    padding: 20px 22px;
    min-width: 3.75rem;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    background-color: $color-black;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 20px;
        max-height: 20px;
    }

    span {
        display: none;
    }
}

@include target(xl, xxl) {
    .book-button {
        display: flex;
        flex-direction: row;
        max-width: unset !important;
        background-color: $color-white;
        transition: transform 0.2s ease;

        img {
            display: none;
        }

        span {
            display: block;
            font-size: 1rem;
            font-weight: $fw-regular;
            line-height: 1;
        }

        &:hover {
            transform: translateY(-3px);
        }
    }
}