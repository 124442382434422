@use 'libs/styles/responsive';
@import 'app/styles/config';
@import 'app/common/input-group/input-group';


.checkbox-group {
  position: relative;

  .checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .checkbox+label {
    display: block;
    padding-left: calc(1rem + 12px);

    a {
      text-underline-offset: 2px;
    }

    @include responsive.target(xs, md) {
      font-weight: 400;
      padding-top: 2px;
    }
  }

  .checkbox+label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;

    display: inline-block;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid $color-black;
    background-repeat: no-repeat;
    background-position: center center;
    transform: translateY(3px);
  }

  .checkbox:checked+label::before {
    border-color: $color-black;
    background-image: url("/static/media/checkbox.svg");
  }

  .checkbox:hover+label::before {
    cursor: pointer;
  }
}

.checkbox-group__subtitle {
  @extend .input-group__subtitle;

  &.checkbox-group__subtitle--error {
    @extend .input-group__subtitle--error;
  }
}