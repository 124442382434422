@import 'app/styles/config';
@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.overlay {
  .overlay__close-button {
    position: absolute;
    top: 1.2rem;
    right: 2rem;
    z-index: 5;

    @include target('xs', 'xl') {
      top: 10px;
      right: 20px;
    }
  }

  .overlay__content {
    display: flex;
    background-color: $color-white;
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    @include target('xs') {
      border-radius: 24px 24px 0px 0px;
    }
  }
}

.overlay__background {
  position: fixed;
  width: 100vw;
  height: 100%;
  inset: 0;
  background-color: $color-black;
  opacity: 0;
  z-index: 2;

  @include target(xs) {
    top: -50px;
  }

  .overlay.active & {
    // Only apply transition for opening-animation.
    transition: opacity 0.3s 0.25s cubic-bezier(.42, 0, .58, 1);
    opacity: 0.7;
  }
}

.overlay__container {
  position: fixed;
  transform: translateX(-100%);
  top: 0;
  bottom: 0;
  width: 95%;
  z-index: 3;
  max-width: 1444px;
  visibility: hidden;

  @include target('xs') {
    top: 44px;
    width: 100%;
    transform: translateY(100%);
  }
}

.overlay__container.active {
  transform: translateX(0);
  // Only apply transition for opening-animation.
  transition: transform 0.3s cubic-bezier(.42, 0, .58, 1);
  visibility: visible;

  @include target('xs') {
    transform: translateY(0);
  }
}

main.container {
  &.in-overlay {
    margin-top: 126px;
    margin-bottom: 0 !important;

    @media screen and (max-width: 1103px) {
      margin-top: 56px;
    }
  }
}