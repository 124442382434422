.icon {
  width: 1.5rem;
  height: 1.5rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  &.icon--info {
    background-image: url('/static/media/info_icon.svg');
  }
}
