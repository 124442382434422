@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.blog-pagination-info {
  font-weight: $fw-medium;
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 2rem;

  @include target(xl, xxl) {
    font-size: 1.25rem;
  }
}

.blog-pager {
  margin-top: 8.75rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include target('xs', 'sm') {
    margin-top: 3rem;
  }

  .blog-pager__container {
    display: flex;
    justify-content: center;
    align-items: center;

    @include target('md', 'xxl') {
      gap: 1.875rem;
    }

    @include target('xs', 'sm') {
      width: 100%;
      gap: 1rem;
    }

    @include target('xs') {
      justify-content: space-between;
    }
  }

  .blog-pager__numbers {
    display: flex;
    justify-content: center;
    align-items: center;

    @include target('md', 'xxl') {
      gap: 1.25rem;
    }

    @include target('xs', 'sm') {
      gap: 1rem;
    }
  }

  .blog-pager-next__link,
  .blog-pager-previous__link {
    color: $color-grey;
    font-family: $font-SuisseIntl;
    font-size: 20px;
    font-style: normal;
    font-weight: $fw-medium;
    text-decoration: none;
    vertical-align: super;
    flex-shrink: 0;

    @include target('xs', 'sm') {
      border-radius: 100%;
      background: $color-grey-lighter;
      display: flex;
      justify-items: center;
      align-items: center;
      width: 25px;
      height: 25px;
    }

    &.disabled {
      color: $color-grey-light;
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      display: none;
      font-size: 1.125rem;
    }

    @include target('xs', 'sm') {
      .label {
        display: none;
      }

      .arrow {
        display: inline-flex;
      }
    }
  }

  .blog-pager-previous__link {
    margin-right: 0;
  }

  .blog-pager-next__link {
    margin-left: 0;
  }

  .blog-pager-page {
    display: inline-flex;
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 100px;
    background: $color-grey-lighter;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $color-grey;
    text-align: center;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: $fw-medium;
    line-height: 108%;

    &.current {
      background: $color-black;
      color: $color-white;
    }

    @include target('xs') {
      width: 50px;
      height: 50px;
      font-size: 1.875rem;
    }
  }

  @media screen and (max-width: 370px) {
    .blog-pager__numbers {
      gap: 0;
      justify-content: space-between;
      width: 100%;
    }

    .blog-pager__container {
      gap: 1rem;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 320px) {
    .blog-pager-page {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.5rem;
    }
  }
}