@import 'app/styles/config';

.input-group {
  position: relative;

  .input {
    margin-top: 0.5rem;
    margin-bottom: 0;

    &:focus {
      background-color: $color-white !important;
      color: $color-black !important;
      border-color: $color-black !important;
    }
  }
}

input {
  background-color: $color-grey-lighter;
  border-radius: 0;

  &:focus {
    background-color: $color-white;
    background-color: $color-grey-light;
  }

  /* remove arrows - Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* remove arrows - Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.input-group__subtitle {
  font-size: 12px;
  line-height: 1;
  margin-top: 0.5rem;

  &.input-group__subtitle--error {
    color: $color-red !important;
    line-height: 1.16;

    .start-calculator__form & {
      display: none;
      position: absolute;
    }
  }
}

.error+.input-group__subtitle--error {
  display: block !important;
}