@import 'app/styles/typography';
@import 'app/styles/config';
@import 'libs/styles/responsive';


.description-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include target(xl, xxl) {
        flex-wrap: nowrap;
        gap: 20px;
    }
}

.description-grid__column {
    margin-bottom: 20px;
    width: 100%;
    flex-shrink: 0;

    @include target(xl, xxl) {
        margin-bottom: 0;
        width: calc(50% - 10px);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.description-grid__item {
    font-size: 1rem;
    line-height: 1.3;
    font-weight: $fw-medium;
    color: $color-grey;
    margin-bottom: 20px;

    @include target(xl, xxl) {
        font-size: 20px;
        margin-bottom: 40px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.description-grid__item__title {
    margin-bottom: 0.5rem;
    color: $color-black;

    @include target(xl, xxl) {
        margin-bottom: 1rem;
    }
}