// @import 'app/styles/config';
@import 'app/styles/typography';

.impressum {
  grid-area: content;

  .impressum__item {
    margin-top: 1rem;
  }
}

.article--legal {
  margin-bottom: 40px;
  border-top: 1px solid $color-black;

  h2 {
    font-family: $font-SuisseIntl;
    font-size: 20px;
    font-weight: $fw-medium;
    line-height: 1.3;
    margin-bottom: 1rem;
  }

  p {
    @extend .text--primary;
    @extend .text--medium;
    @extend .text--disclaimer;
  }

  strong,
  b {
    // font-weight: $fw-bold;
    color: $color-grey-dark;
  }

  ul {
    list-style: disc;
    list-style-position: outside;
    margin-left: 1.5em;

    li {
      @extend .text--primary;
      @extend .text--medium;
      @extend .text--disclaimer;
      margin-top: 0.25rem;

      a {
        @extend .link;
        text-decoration: none;
      }
    }
  }
}