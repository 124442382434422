@import 'app/styles/config';

.button-group {
  background-color: $color-grey-lighter;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 100px;
  max-width: 21.0625rem;
  max-height: 3rem;
  position: relative;

  .button {
    background-color: transparent;
    align-self: center;
    height: 3.375rem;
    transition: 1s;
    z-index: 1;

    &.button--medium {
      padding: 0 1rem;
    }

    &.button--active {
      color: $color-white;
    }
  }
}

.button__background {
  width: 6.875rem;
  height: 3.375rem;
  position: absolute;
  border-radius: 100px;
  top: -3px;
  z-index: -1;
  background-color: $color-black;
  transition: 0.25s ease;

  &.button__background--left {
    width: 6.7rem;
    left: -15px;
    transform: translate(0%, 0);
  }

  &.button__background--right {
    left: 70%;
    transform: translate(-50%, 0);
    width: 8rem;
  }
}