@import 'app/styles/config';
@import 'libs/styles/responsive';

.select {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: $color-black;
    font-weight: $fw-medium;
    margin-bottom: 0.5rem;
  }

  .select__wrapper {
    inset: 0;
    position: relative;
    width: 100%;
  }

  .select__outside {
    position: fixed;
    inset: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
  }

  .select__element {
    cursor: pointer;
    width: 100%;
    border-left: 3px solid $color-black;
    padding: 1.25rem 1rem;
    font-size: 1rem;
    line-height: 1.25em;
    color: $color-black;
    background-color: $color-grey-lighter;
    font-family: $font-SuisseIntl;
  }

  .select__options {
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    display: none;
    flex-direction: column;
    background-color: $color-white;
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.08);

    .select__options__option {
      cursor: pointer;
      transition: background-color 0.1s ease-out;
      padding: 1.125rem 1.25rem;
      font-size: 1rem;

      &:hover {
        background-color: $color-grey-lighter;
      }
    }

    .select__options__option--active {
      background-color: $color-grey-light;
    }
  }

  .select__arrow {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color-grey-dark;
    pointer-events: none;
  }
}