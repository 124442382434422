@import 'app/styles/config';
@import 'libs/styles/responsive';

.switch-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    width: max-content;
    position: relative;
    inset: 0;
    background-color: rgba($color-white, 0.05);
    color: $color-grey-light;
    border-radius: 4rem;

    .switch-buttons__button {
        font-size: 0.875rem;
        line-height: 1.125rem;
        text-align: center;
        padding: 1.125rem;
        cursor: pointer;
        position: relative;
        inset: 0;
        z-index: 10;
        transition: color 0.15s ease-out;

        &.active {
            color: $color-black;
        }
    }

    .switch-buttons__selected {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-white;
        border-radius: 4rem;
        width: 0;
        height: 100%;
        z-index: 5;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
        transition: transform 0.2s ease-out, width 0.1s;
        transform-origin: center;
    }
}