@import 'libs/styles/responsive';

.number-cards {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 20.9375rem;
  row-gap: 1.625rem;

  @include target('md', 'xxl') {
    grid-template-columns: repeat(2, minmax(20rem, 27.5rem));
    column-gap: 1.25rem;
  }
}
