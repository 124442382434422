@import 'app/styles/config';
@import 'libs/styles/responsive';
@import 'libs/styles/fonts';

.hub-container {
    .sections-links {
        color: $color-black;
        font-size: 20px;
        font-weight: $fw-medium;
        line-height: 1.3;
        margin-bottom: 140px;

        .section-links__link {
            margin-bottom: 8px;
            text-underline-offset: 3px;
            text-decoration-skip-ink: none;
        }
    }
}