@use 'app/styles/config';
@use 'libs/styles/responsive';
@import 'app/styles/typography';
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";


.quote {
    padding-left: 8px;
    border-left: 3px solid config.$color-black;

    .text-plugin-content--blog & {
        border-left: none;
        padding-left: 0;
    }
}

.quote__link {
    display: block;
    text-decoration: none;
}

.quote__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    appearance: none;
    background: transparent;
    border: none;
    gap: 1rem;
    margin-top: 30px;
    font-size: 16px;
    cursor: pointer;
    font-family: inherit;
    color: config.$color-black;

    span {
        color: inherit;
    }

    .arrow-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        background-color: $color-black;
        color: config.$color-black;
        transition: background-color 0.2s ease;
    }
}

.quote__img {
    filter: brightness(0);
    margin-bottom: 20px;
}

.quote__text {
    font-family: $font-Tiempos;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.02em;

    @include responsive.target(xl, xxl) {
        font-size: 24px;
    }
}

.quote__author {
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.29;

    @include responsive.target(xl, xxl) {
        margin-top: 40px;
    }

    cite {
        display: block;
        font-weight: 400;

        a {
            text-decoration: none;
        }
    }
}

.quotes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 20px;
    align-items: flex-start;

    .glide--carousel & {
        gap: 0;
    }

    &.quotes-container--no-mt {
        margin-top: 0;
    }

    @include responsive.target(md, xxl) {
        flex-direction: row;

        .quote {
            width: calc(50% - 10px);
        }
    }

    @include responsive.target(xl, xxl) {
        margin-top: 30px;
    }

    .section__content--no-margin & {
        margin-top: 0;
    }
}

.glide+.limiter-button {
    margin-top: 20px;

    @include responsive.target(xl, xxl) {
        margin-top: 40px;
    }
}