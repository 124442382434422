@import 'app/styles/config';

.contact-container {
  width: 100%;
}

.accordion {
  grid-area: content;
}

