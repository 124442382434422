@import 'app/styles/config';
@import 'libs/styles/responsive';

.header {
  min-height: 40px;

  @include target(xs, lg) {
    z-index: 2;
  }

  @include target(lg, xxl) {
    min-height: 75px;
  }

  &.header--mobile-nav-open {
    z-index: 1000;
  }

  &.header--image {
    background-size: 100% 20.9375rem;
    height: 12.625rem;
    flex: 0 0 auto;
  }

  &.header--green {
    background: $color-green;
  }

  &.header--home {
    height: 100vh;
    max-height: -webkit-fill-available; // to ensure correct height on iOS Safari
  }

  &.header--grey-image {
    // background-image: url($header-background-grey-path);
    background: linear-gradient(180deg,
        $color-grey-light 50%,
        $color-white);
  }

  &.header--grey {
    background: linear-gradient(180deg,
        $color-grey-light 50%,
        $color-white 50%);
  }

  &.header--red {
    background-image: url($header-background-red-path);
  }

  &.header--blue {
    background-image: url($header-background-blue-path);
  }

  &.header--blue-light,
  &.header--blue-dim {
    background: linear-gradient(180deg,
        $color-blue-light 50%,
        $color-white 50%);
  }

  &.header--pink {
    background: $color-pink;
  }

  &.header--rainbow {
    background: linear-gradient(90deg,
        #E7EBF8 33%,
        #CED8F2, #E3F2CE, #FBF0F5);

    .headline1 {
      max-width: 920px;
      width: auto;
    }
  }

  @include target('xl', 'xxl') {
    &.header--image {
      background-size: 100% 47.5rem;
      height: 720px;
    }
  }

  @include target('xs', 'lg') {
    &.header--product {
      &.header--pink {
        background: $color-pink;
      }

      &.header--green {
        background: $color-green;

        .page-title-block__headline-wrapper {
          background: $color-blue-dim;
        }
      }

      &.header--blue-light {
        background: $color-blue-light;

        .page-title-block__headline-wrapper {
          background: $color-red-dim;
        }
      }

      .start-calculator .start-calculator-button {
        margin: 0;
      }

      .page-title-block__headline-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 120px 1.25rem 20px 1.25rem;

        .headline1 {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  &.header--product {
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      .headline1 {
        font-size: 48px !important;
      }
    }

    @include target(xl, xxl) {
      [lang="fr-ch"] & {
        .headline1 {
          font-size: 56px;
        }
      }
    }
  }

  @include target('xl', 'xxl') {
    &.header--product {
      position: relative;
      background-size: 100vh;
      max-width: 100vw;
      height: 81vh;
      min-height: 690px;
      max-height: 800px;

      &.header--pink {
        background: linear-gradient(90deg,
            $color-pink 50%,
            $color-green-dim 50%);

        .page-title-block__headline-wrapper {
          background: $color-green-dim;

          &::before {
            background: $color-green-dim;
          }
        }
      }

      &.header--green {
        background: linear-gradient(90deg,
            $color-green 50%,
            $color-blue-dim 50%);

        .page-title-block__headline-wrapper {
          background: $color-blue-dim;

          &::before {
            background: $color-blue-dim;
          }
        }
      }

      &.header--blue-light {
        background: linear-gradient(90deg,
            $color-blue-light 50%,
            $color-red-dim 50%);

        .page-title-block__headline-wrapper {
          background: $color-red-dim;

          &::before {
            background: $color-red-dim;
          }
        }
      }

      &.header--blue-dim {
        background: linear-gradient(90deg,
            $color-blue-light 50%,
            $color-blue-dim 50%);

        .page-title-block__headline-wrapper {
          background: $color-blue-dim;

          &::before {
            background: $color-blue-dim;
          }
        }
      }

      .page-title-block {
        position: absolute;
        top: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 1440px;
        display: flex;
        align-items: flex-end;
        padding: 0 2.5rem;
        height: 100%;
        min-height: inherit;
        max-height: inherit;

        @media screen and (min-width: 1450px) {
          left: calc((100vw - 1460px) / 2);
        }
      }

      .headline1 {
        margin-bottom: 1.875rem;
        width: 100%;
      }

      .start-calculator__form {
        margin: 0;
      }

      .page-title-block__headline-wrapper {
        position: relative;
        inset: 0;
        height: 100% !important;
        min-height: inherit;
        max-height: inherit;
        gap: 2rem;
        justify-content: flex-end;
        padding: 0;
        padding-top: 16rem;
        padding-bottom: 4.5rem;
        background-position: -50% 0;
        width: 66.18%;

        @media screen and (min-width: 1024px) and (max-width: 1280px) {
          padding-bottom: 2.5rem;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          transform: translateX(-135px);
        }
      }

      .page-title-block .page-title-block__headline-wrapper .container {
        width: 100%;
        margin-top: 0;
        padding: 0;
        position: relative;
        inset: 0;
        z-index: 1;
      }

      .start-calculator {
        margin: 0;
        display: flex;

        .start-calculator__explanation.pillar-explanation {
          display: none;
        }

        .start-calculator__form {
          .input-group__label {
            font-size: 1rem;
            font-weight: $fw-regular;
          }

          .input.error {
            background-color: $color-pink;
          }

          .input-group__subtitle--error {
            color: $color-white !important;
          }

          input:not(.input.error) {
            color: $color-white;
            border-color: $color-white;
            background-color: rgba(255, 255, 255, 0.2);

            &::placeholder {
              color: $color-grey-medium !important;
              opacity: 1;
              /* Firefox */
            }

            &::-ms-input-placeholder {
              /* Edge 12 -18 */
              color: $color-grey-medium !important;
            }
          }

          .input {
            &:focus {
              box-shadow: inset 0px -1px 0px 0px $color-white;
            }

            &::placeholder {
              color: $color-grey-medium;
            }
          }
        }

        .start-parametrized-calculator-button {
          margin-left: 0;

          p {
            color: $color-white;
            font-weight: $fw-regular;
            // white-space: nowrap;
          }

          .arrow-container {
            background-color: $color-white;

            img {
              filter: invert(1);
            }
          }
        }
      }

      .nav:not(.nav--sticky) {

        .nav__button-link,
        .nav__link {
          color: $color-white;
        }

        .dropdown .dropdown__content:before,
        .nav__top-link:hover {
          border-color: $color-white;
        }

        .dropdown .dropdown__content .nav__link,
        .dropdown:hover .nav__button-link,
        .nav__link:hover {
          color: $color-white !important;
        }

        .login-link:hover {
          background-color: rgba(255, 255, 255, 0.05);
        }

        .register-link {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
}

.header__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 100px;
  left: 20px;
  right: 20px;
  z-index: 1;
  max-width: calc(950px + ((100vw - 1440px) / 2));

  .headline1 {
    margin-bottom: 20px;
  }

  @include target(xl, xxl) {
    left: 0;
    top: 0;
    right: auto;
    width: 69%;
    background-color: $color-white;
    padding: 100px 40px 80px;
  }
}

.header__content__inner {
  max-width: 880px;
  margin-left: auto;
}