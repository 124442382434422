@import 'app/styles/config';
@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.home {
  .headline1 {
    margin: 2.3125rem 0 1.25rem 0;
  }

  .overview-link {
    margin-top: 15px;
  }

  .home__partners-block {
    margin-top: 60px;
    margin-bottom: 140px;

    @include target(xs, lg) {
      margin-top: 30px;
      margin-bottom: 70px;
    }

    .text--primary {
      margin-bottom: 1.5rem;
      font-weight: $fw-medium;
    }
  }

  .home__partners-wrap {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px 50px;
    align-items: flex-start;

    @include target(xl, xxl) {
      flex-direction: row;
      grid-area: content;
      gap: 30px 60px;
      align-items: center;
    }

    @include target(xxl) {
      gap: 30px 45px;
    }
  }

  .home__partner-logo {
    display: block;
    height: 55px;
    max-width: 160px;
    filter: grayscale(1);

    @include target(xl, xxl) {
      max-width: calc(50% - 25px);
    }

    @include target(xxl) {
      max-width: 155px;
    }
  }

  @include target('xl', 'xxl') {
    .home__welcome-container {
      position: absolute;
      top: 0;
      z-index: 1;
      box-sizing: content-box;
      width: fit-content;

      .headline1 {
        margin: 0 0 1.875rem;
      }
    }

    .home__welcome-text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 68%;
      height: 640px;
      padding: 5rem 2.5rem 5rem 0;
      background-color: $color-white;

      @include target('xxl') {
        width: 940px;
      }
    }

    .home__welcome-container:before {
      content: '';
      background-color: $color-white;
      position: absolute;
      height: 100%;
      width: 100vw;
      left: -100vw;
      top: 0;
      z-index: -1;
    }

    .overview-link {
      display: grid;
      grid-template-columns: 33.82% 66.18%;
      margin-top: 1.875rem;
      grid-template-areas: '. link';

      .link {
        font-size: 16px;
        line-height: 1;
        grid-area: link;
      }
    }

    .home__partners-block {
      display: grid;
      grid-template-columns: 33.82% 66.18%;
      grid-template-areas:
        '. content_title'
        '. content';

      p {
        font-size: 36px;
        line-height: 1;

        &:first-child {
          grid-area: content_title;
        }
      }
    }
  }

  .start-calculator {
    .start-calculator__explanation {
      margin-bottom: 30px;
    }

    .button--white {
      color: $color-white;
      background-color: $color-black;
    }
  }
}