@use 'libs/styles/responsive';
@use 'app/styles/config';

.glide--no-mt {
    margin-top: 0 !important;

    .quotes-container {
        margin-top: 0;
    }
}

.glide-scroller {
    margin-top: 15px;

    &.glide--carousel {
        @include responsive.target(xs, sm) {
            .glide__bullets {
                display: flex;
            }
        }
    }

    .glide__track {
        transition: height 0.25s ease-out;

        @include responsive.target(xl, xxl) {
            overflow: visible;
        }
    }

    .glide__bullets {
        display: none;
        flex-direction: row;
        gap: 8px;
        margin-top: 20px;
        justify-content: center;

        .glide__bullet {
            width: 8px;
            height: 8px;
            border-radius: 8px;
            border: none;
            background-color: config.$color-grey-light;

            &.glide__bullet--active {
                background-color: config.$color-black;
            }
        }
    }
}