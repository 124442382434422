@import 'app/styles/config';
@import 'libs/styles/responsive';
@import 'libs/styles/fonts';

.tooltip {
  position: relative;
  inset: 0;
  display: inline-block;
  cursor: pointer;

  .tooltip__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-grey;
  }

  .tooltip__body {
    display: none;
    position: absolute;
    background-color: $color-grey-lighter;
    color: $color-black;
    padding: 1rem;
    font-size: 0.875rem;
    font-weight: $fw-regular;
    line-height: 1.28;
    z-index: 200;
    width: max-content;
    max-width: 290px;
    margin: 0 1rem;
    box-sizing: border-box;
    flex-direction: column;
    text-align: left;
    gap: 0.5rem;

    a {
      font-weight: $fw-medium;
      color: $color-grey-dark;
      text-decoration: underline;
      max-width: inherit;
      word-break: break-word;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      li {
        position: relative;
        padding-left: 1em;

        &::before {
          position: absolute;
          content: "–";
          left: 0;
        }
      }
    }

    &.tooltip__body--left-top {
      bottom: 0%;
      left: calc(-50% - 0.5rem);
      transform: translate(-100%, 0%);
    }

    &.tooltip__body--left-center {
      top: 50%;
      left: calc(-50% - 0.5rem);
      transform: translate(-100%, -50%);
    }

    &.tooltip__body--left-bottom {
      top: 0%;
      left: calc(-50% - 0.5rem);
      transform: translate(-100%, 0%);
    }

    &.tooltip__body--right-top {
      bottom: 0%;
      right: calc(-50% - 0.5rem);
      transform: translate(100%, 0%);
    }

    &.tooltip__body--right-center {
      top: 50%;
      right: calc(-50% - 0.5rem);
      transform: translate(100%, -50%);
    }

    &.tooltip__body--right-bottom {
      top: 0%;
      right: calc(-50% - 0.5rem);
      transform: translate(100%, 0%);
    }

    &.tooltip__body--mobile-center {
      bottom: 25px;
      left: 0;
      transform: translateX(-50%);
    }
  }
}

.tooltip-open {
  .tooltip__body {
    display: flex;
  }
}