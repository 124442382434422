@import 'app/styles/config';
@import 'libs/styles/fonts';

@mixin mix__slider__thumb {
    background: $color-white;
    cursor: pointer;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
}

.slider {
    .slider__wrapper {
        position: relative;
        inset: 0;
        width: 100%;

        .slider__input {
            width: 100%;
            -webkit-appearance: none;
            appearance: none;
            height: 0.375rem;
            background-color: rgba(255, 255, 255, 0.1);

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                @include mix__slider__thumb;
            }

            &::-moz-slider-thumb {
                @include mix__slider__thumb;
            }

            &::-ms-slider-thumb {
                @include mix__slider__thumb;
            }
        }

        .slider__track {
            margin-top: 2px;
            width: 50%;
            height: 0.375rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            z-index: 2;
            background-color: $color-pink-dim;
            pointer-events: none;
        }

        .slider__thumb {
            position: absolute;
            pointer-events: none;
            width: 2.75rem;
            height: 2.75rem;
            border-radius: 50%;
            background: $color-white;
            top: 50%;
            left: 50%;
            transform: translate(calc(-50% + 2px), calc(-50% + 2px));
            transform-origin: left;
            z-index: 3;
            box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.08);
        }
    }

    .slider__texts {
        margin-top: 19px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 1.16;
        color: $color-white;
        font-weight: $fw-regular;
    }
}