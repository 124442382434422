/*********** Colors ***********/

$color-black: #1a1a1a;
$color-black-hover: #3a3a3a;
$color-grey: #8c8c8c;
$color-grey-dark: #5f5f5f;
$color-grey-light: #e5e5e5;
$color-grey-lighter: #f4f4f4;
$color-grey-medium: #bababa;
$color-green: #e3f2ce;
$color-green-dim: #3c4036;
$color-green-dark: #929982;
$color-green-dark-hover: #a6af95;
$color-green-light: #f1f8e7;
$color-green-bright-bg: $color-green-light;
$color-blue-light: #ced8f2;
$color-blue-dim: #1c2742;
$color-blue-dimmer: #75809a;
$color-blue-bright-bg: #E7EBF8;
$color-white: #ffffff;
$color-grey-transparent: rgba(255, 255, 255, 0.05);
$color-red: #cc0000;
$color-red-light: #fbf0f5;
$color-red-dim: #591b30;
$color-red-05: #937A91;
$color-pink: #f2cedc;
$color-pink-dim: #a291a9;
$color-pink-bright-bg: #FBF0F5;
$color-table-highlight-green: #f1f8e7;

/*************************************/

/*********** Breakpoints ***********/

$breakpoints: (
  // Breakpoint Definition
  xs: 0,
  // Extra small screen / phone
  sm: 576px,
  // Small screen / phone
  md: 768px,
  // Medium screen / tablet
  lg: 992px,
  // Large screen / desktop
  xl: 1024px,
  // Extra large screen / wide desktop
  xxl: 1300px,
  // Double extra large screen / wide desktop
);

/******************************/

/*************** Fonts ***************/

$font-OpenSans: 'OpenSans';
$font-SuisseIntl: 'SuisseIntl';
$font-Tiempos: 'Tiempos';

// /*************************************/

$header-background-grey-path: 'media/header_background_grey.png';
$header-background-blue-path: 'media/header_background_blue.png';
$header-background-red-path: 'media/header_background_red.png';

$container-width: 1440px;