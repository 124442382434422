@import 'app/styles/config';
@import 'libs/styles/responsive';
@import 'libs/styles/fonts';

.hypothekenrechner__partnership {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1em;
  margin: 1.125rem 0;
  gap: 1rem;

  img {
    height: 27px;
  }

  @include target(xs) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;

    img {
      height: 22px;
    }
  }
}

.hypothekenrechner {
  .arrow-container {
    border-radius: 30px;
    max-height: 3.75rem;
    max-width: 3.75rem;
    background-color: $color-white;
    color: $color-black;
    padding: 1.3rem 1.125rem 1.3rem 0.97rem;
    align-self: center;
    align-items: center;
  }

  .button-container {
    display: flex;
    color: inherit;
    flex-direction: row;
    justify-content: flex-start;
    height: 3.75rem;
    align-self: center;
    align-items: center;

    p {
      margin-left: 16px;
      font-size: 16px;
      font-weight: $fw-regular;
    }
  }

  .hypothek-calculator {
    .calculator__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1.25rem;
      grid-template-rows: 7rem;

      .input-group {
        .input-group__label {
          display: block;
          color: $color-white;
          font-size: 16px;
          font-style: normal;
          font-weight: $fw-medium;
          line-height: 1.25;
        }

        .input {
          color: $color-white;
          border-color: $color-white;
          background-color: rgba($color-white, 0.2);
          font-size: 16px;
          font-style: $fs-normal;
          font-weight: $fw-regular;
          line-height: 1.25;
        }
      }
    }

    @include target(xs, lg) {
      background-color: $color-blue-dim;

      .container:not(.container--headline) {
        display: none;
      }
    }
  }

  .hide-on-mobile {
    @include target('xs', 'lg') {
      display: none !important;
    }
  }

  .show-on-mobile {
    @include target('xl', 'xxl') {
      display: none !important;
    }

    &.page-title-block__headline-wrapper {
      background-color: $color-blue-dim;
      padding-top: 0;

      a.button-container {
        color: $color-white;
        text-decoration: none !important;
      }
    }
  }
}