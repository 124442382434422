@import 'app/styles/config';
@import 'libs/styles/responsive';

.section-premain {
  background-color: $color-black !important;

  @include target('xl', 'xxl') {
    background-color: transparent !important;
  }

  .premain {
    .premain__header {
      grid-area: content;
    }
  }
}

.not-found-articles {
  grid-area: content;

  .not-found-article {
    margin-top: 1rem;
    font-weight: $fw-medium;
    line-height: 1.25;

    .not-found-article__paragraph {
      color: $color-grey-dark;
    }
  }
}