@use 'libs/styles/responsive';
@use 'app/styles/typography';
@import 'app/styles/fonts';


.case-study {
  .list--outlined {
    margin-bottom: 1.875rem;
  }

  .text-plugin-content {
    p {
      @extend .text--primary;
      font-family: $font-SuisseIntl;
      margin-bottom: 1rem;

      @include responsive.target(xl, xxl) {
        font-weight: $fw-medium;
        font-size: 1.25rem;
      }

      &.text--outlined {
        margin-bottom: 0;

        &+.text--outlined {
          margin-top: 1.25rem;
        }

        &+p:not(.text--outlined) {
          margin-top: 1rem;
        }
      }
    }

    h2 {
      font-weight: $fw-medium;
      margin-top: 30px;
      margin-bottom: 0.5rem;

      @include responsive.target(xl, xxl) {
        font-size: 36px;
        margin-top: 60px;
        margin-bottom: 1rem;
      }
    }
  }

  .text-plugin-content+.card {
    margin: 60px 0;
  }
}