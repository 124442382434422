@import 'app/styles/config';
@import 'app/styles/typography';
@import 'libs/styles/responsive';

.card {
  background-color: $color-grey-lighter;
  padding: 20px 20px 100px;
  display: flex;
  flex-direction: column;

  @include target(xl, xxl) {
    padding: 30px 20px;
    transform: translateY(0);
    transition: box-shadow 0.2s ease-out, transform 0.2s ease-out;
    box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.0);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.50);
    }
  }

  .card__link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    height: 100%;
  }

  &.card--no-hover {
    &:hover {
      box-shadow: none;
      transform: none;
    }
  }

  &.card--green {
    background-color: $color-green-bright-bg;
  }

  &.card--green-dim {
    background-color: $color-green-dim;
    color: $color-white;
  }

  &.card--blue {
    background-color: $color-blue-bright-bg;
  }

  &.card--blue-dim {
    background-color: $color-blue-dim;
    color: $color-white;
  }

  &.card--pink {
    background-color: $color-pink-bright-bg;
  }

  &.card--red-dim {
    background-color: $color-red-dim;
    color: $color-white;
  }

  &.card--hidden {
    display: none;
  }

  &.card--cta {
    @include target(xs, lg) {
      padding-bottom: 20px;
    }
  }

  @include target('xl', 'xxl') {
    min-height: 475px;
    padding-bottom: 40px
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.card__logo {
  height: 48px;
  margin-top: -2px;
  margin-bottom: 0.75rem;
  object-fit: contain;
  align-self: flex-start;
  max-width: 100%;
}

.card__text {
  @extend .text--medium;
  @extend .text--primary;
  color: $color-grey;
  margin-bottom: 1.25rem;

  .card--serif & {
    font-family: $font-Tiempos;
    font-weight: $fw-regular;
    font-size: 1.25rem;

    @include target(xl, xxl) {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }

  .card--blue-dim & {
    color: rgba($color-white, 0.6);
  }

  .card--green-dim & {
    color: rgba($color-white, 0.6);
  }

  .card--red-dim & {
    color: rgba($color-white, 0.6);
  }

  .card--pink-font & {
    color: $color-pink;
  }

  .card--blue-font & {
    color: $color-blue-light;
  }

  .card--green-font & {
    color: $color-green;
  }
}

.card__author {
  font-family: $font-SuisseIntl;
  font-size: 0.875rem;
  font-weight: $fw-medium;
  line-height: 1.125;
  margin-bottom: 1.25rem;
  color: $color-white;
}

.card__author2 {
  margin-top: -1rem;
  margin-bottom: 1.25rem;
  color: $color-white;
  font-size: 0.875rem;
  font-weight: $fw-regular;
}

.card__author2__link {
  color: inherit;
}

.card__title {
  @extend .text--medium;
  @extend .text--primary;
}

.card__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  text-align: left;
  font-size: 1rem;
  line-height: 1;
  margin-top: auto;
  transition: color 0.2s ease;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;

  .card--cta & {
    @include target(xs, lg) {
      margin-top: 80px;
    }
  }

  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: $color-black;
    margin-right: 1rem;
    transition: background-color 0.2s ease;
  }

  &:hover {
    color: $color-black-hover;

    .arrow-container {
      background-color: $color-black-hover;
    }
  }

  .card--blue-dim &,
  .card--red-dim &,
  .card--green-dim & {
    color: $color-white;

    .arrow-container {
      background-color: $color-white;

      img {
        filter: invert(1);
      }
    }
  }
}

.cards-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;

  .glide--carousel & {
    @include target(xs, lg) {
      margin-top: 0;
      flex-direction: row;
      gap: 0;
    }
  }

  @include target('xl', 'xxl') {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    margin-top: 30px;

    &.cards-wrap--high {
      .card {
        min-height: 570px;
      }
    }

    &.cards-wrap--grid {
      padding-left: 33.82%;
    }

    &[data-col="2"] {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.quotes-title-container {
  margin-top: 30px;
  margin-bottom: 1rem;

  @include target('xl', 'xxl') {
    margin-top: 60px;
    grid-template-areas: '. title';
    display: grid;
    grid-template-columns: 33.82% 66.18%;
  }

  .quotes-title {
    grid-area: title;
    font-size: 24px;
    text-align: left;

    @include target('xl', 'xxl') {
      font-size: 36px;
    }
  }
}