@import 'app/styles/config';

.input {
  width: 100%;
  padding: 1.25rem 0.75rem;
  box-sizing: border-box;
  border-left: 3px solid $color-black;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
  font-size: 1rem;
  font-family: inherit;

  &:focus {
    outline: none;
    background-color: $color-white;
    box-shadow: inset 0px -1px 0px 0px $color-black;
  }

  &.input--dark {
    color: $color-white;
    background-color: $color-black;
    border-left: 3px solid $color-white;
    border-bottom: 1px solid $color-white;
  }

  &.input--error {
    background-color: $color-red-light !important;
    border-left: 3px solid $color-red;
  }

  &.error {
    background-color: $color-red-light;
    border-color: $color-red;
    line-height: 1.16;
  }
}