@import 'libs/styles/responsive';
@import 'app/styles/typography';

.list {
  list-style: none;
  margin: 1rem 0 1.875rem 2.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  &+p {
    margin-top: 1rem;
  }

  .list__item {
    line-height: 1.25;
    padding-left: 1.375rem;

    a {
      @extend .link;
      text-decoration: none;
    }
  }

  .list__item:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  &.list--outlined {
    margin-left: 0;

    .list__item {
      border-left: 3px solid;
      padding-left: 6px;
    }
  }
}