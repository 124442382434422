@import 'app/styles/config';
@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

/**
* Due to initial development by external devs,
* the mobile/desktop version is not built based
* on a mobile-first-approach.
*/

.nav {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;

  .container {
    display: flex;
    height: fit-content;
    justify-content: space-between;
    padding: 1rem 20px 0 20px;

    @include target(xs, lg) {
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      min-height: 40px;
      margin: 0;
      padding: 0;
    }

    @include target('xl', 'xxl') {
      display: grid;
      grid-template-columns: calc(33.82% - 6px) 66.18%;
      padding: 1.375rem 2.5rem 1.375rem;
      align-items: center;
    }
  }

  @include target(xs, lg) {
    &:not(.nav--mobile-open-delayed) {
      height: 120px;
    }


    &::before {
      content: " ";
      position: fixed;
      top: 0;
      left: 0;
      bottom: 100%;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: rgba($color-white, 0.5);
      transform: scaleY(0%);
      transform-origin: top;
      transition: all .32s cubic-bezier(.4, 0, .6, 1);
    }

    &.nav--show-mobile {
      &::before {
        transform: scaleY(100%);
        background-color: $color-white;
      }
    }
  }

  @include target('xl', 'xxl') {
    z-index: 2;
    font-weight: $fw-medium;
    line-height: 1.25;

    .dropdown__background {
      position: absolute;
      background-color: $color-white;
      height: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      transition: height 0.20s ease-in-out;
    }

    &.nav--sticky {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      background-color: $color-white;

      .dropdown:hover .dropdown__background {
        display: block;
        height: 15rem;
      }
    }

    &.nav--white-background {
      .dropdown:hover .dropdown__background {
        display: block;
        height: 15rem;
      }
    }

    .nav-main-links {
      align-self: flex-end;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: -3px;
      gap: 0.875rem;

      @include target('xxl') {
        gap: 1.875rem;
      }

      @media screen and (min-width: 1024px) and (max-width: 1100px) {
        gap: 3px;

        .dropdown__content {
          padding-left: 3px;

          &::before {
            left: 0px;
            border-left-width: 2px;
          }
        }

        .nav__link {
          border-left-width: 2px !important;
        }
      }
    }

    .nav__button-link {
      padding: 0 3px;
      margin: 0;
    }

    .nav__top-link {
      border-left: 3px solid transparent;
      padding-left: 3px;

      &:hover {
        border-left: 3px solid $color-black;
      }
    }
  }

  &.in-overlay {
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 56px;
    background-color: $color-white;
    z-index: 3;
    padding-top: 0;
    padding-bottom: 0;

    .container {
      grid-template-columns: 460px calc(100% - 460px);

      @media screen and (max-width: 1103px) {
        display: flex;
        color: $color-black;
        font-size: 16px;
        padding-left: 20px;
        font-style: normal;
        font-weight: $fw-medium;
        line-height: 1.625;
        align-items: center;
        justify-content: center;

        .nav__logo-container {
          display: none;
        }
      }
    }

    .nav-main-links {
      align-self: inherit;
      display: block;
    }

    .arrow-back {
      position: absolute;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      display: none;
    }

    @include target('xs', 'xl') {
      .arrow-back {
        display: block;
        left: 20px;
        cursor: pointer;
      }
    }
  }
}

.nav__logo {
  width: 119px;

  @include target(xl, xxl) {
    width: 160px;
  }
}

.nav__logo-link {
  text-decoration: none;
  z-index: 1;
  transition: all 0.25s ease;
  align-self: flex-start;

  @include target(xs, lg) {
    position: absolute;
    top: 1rem;
    left: 20px;
  }
}

.nav__logo-text {
  position: absolute;
  transform: translateY(-2px);
  color: $color-grey-medium;
  font-size: 31px;
  font-style: normal;
  font-weight: $fw-medium;
  line-height: 1;
  margin-left: 4px;

  @include target('xs', 'lg') {
    font-size: 25px;
  }
}

.nav__toggler {
  display: none;

  img {
    display: block;
  }
}

.nav__content {
  display: flex;
  justify-content: space-between;
}

.nav__links-container {
  padding: 0;
  list-style: none;
}

.nav__links-container__item--home {
  display: none;
}

.nav__link {
  text-decoration: none;

  .nav__links-container & {
    &:not(.nav__top-link):hover {
      color: $color-grey-dark;
    }
  }
}

.nav--overlay {
  .nav-main-links {
    @include target(xl, xxl) {
      font-size: 20px;
    }
  }
}

.login-link {
  color: $color-black;
  background-color: $color-grey-lighter;
  transition: 0.25s ease;

  &:hover {
    color: $color-white;
    background-color: $color-black;
  }

  @include target('xl', 'xxl') {
    padding: 0.75rem 1.25rem;
    background-color: transparent;

    &:hover {
      background-color: $color-black;
      color: $color-white;
    }
  }
}

.register-link {
  background-color: $color-black;
  color: $color-white;
  position: relative;
  z-index: 2;
  transition: background-color 0.25s ease;

  &:hover {
    background-color: $color-black-hover;
  }

  @include target('xl', 'xxl') {
    padding: 0.75rem 1.25rem;
    position: relative;
    margin-left: 0.3125rem;
  }
}


@include target(xs, lg) {
  .nav--overlay {
    .nav-main-links {
      display: none;
    }

    .nav__logo-container {
      display: none;
    }
  }

  /* Mobile navigation below */

  .nav__toggler {
    position: absolute;
    top: 1rem;
    right: 20px;
    display: block;
    align-self: flex-end;

    .nav--show-mobile & {
      top: 14px;
      right: 13px;

      img {
        content: url('/static/media/close_icon.svg');
      }
    }
  }

  .nav__content {
    width: 100%;
    height: calc(100% - 56px); // 56px nav__toggler height
    margin-top: 56px;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease 0s, visibility 0s linear 0.25s, transform 0.4s ease-out 0.15s;

    @include target(xs, lg) {
      display: flex;
      flex-direction: column;
      transform: translateY(-25px);
    }

    .nav--show-mobile & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: opacity 0.75s ease-in 0.25s, visibility 0s linear, transform 0.4s ease-out 0.15s;
    }
  }

  .nav__auth-links {
    display: none;
    flex-direction: row;
    gap: 20px;
    padding: 20px 20px 0 20px;
    justify-content: center;
    align-items: flex-start;
    height: 100px;
    flex-shrink: 0;
    border-top: 1px solid $color-grey-light;

    .nav__link {
      width: 100%;
      padding: 20px;
      text-align: center;
      font-size: 16px;
      font-weight: $fw-regular;

      @include target(xs, lg) {
        padding: 20px 10px;
      }
    }
  }

  .nav__links-container {
    overflow-y: auto;
    padding: 40px 20px;
  }

  .nav__links-container__item {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .nav__links-container__item--home {
    display: block;
  }

  .nav__link,
  .nav__button-link {
    font-size: 20px;
    line-height: 1;
    font-weight: $fw-medium;
  }

  .nav__button-link {
    position: relative;
    width: 100%;
    text-align: left;

    &::after {
      display: block;
      position: absolute;
      content: " ";
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(0deg);
      height: 24px;
      width: 24px;
      background: url('/static/media/toggle.svg') center no-repeat;
      background-size: contain;
    }

    .dropdown--open & {
      &::after {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
  }

  .nav--show-mobile {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .nav__logo-link {
      position: absolute;
      top: 1rem;
      left: 20px;
      opacity: 0;
      visibility: hidden;
    }

    .nav__auth-links {
      display: flex;
    }
  }
}