@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.blog-article__container {
  a {
    text-decoration: none;
  }

  display: block;
  max-width: $container-width;

  @include target('xs', 'sm') {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }

  .article__top {
    width: 100%;
    position: relative;
    height: 530px;
    margin-bottom: 60px;

    @include target('md', 'xl') {
      max-height: 530px;
      height: 40vw;
    }

    @include target('xs', 'sm') {
      display: flex;
      flex-direction: column;
      height: auto;
      margin-bottom: 0;

      .article__header {
        position: relative;
        order: 2;
        padding: 14px 20px;
        min-height: unset;
        max-width: 670px;
        width: 100%;
        align-self: center;
      }

      .article__banner {
        position: relative;
        order: 1;
        height: auto;
        aspect-ratio: 376 / 244;

        img {
          position: relative;
        }
      }
    }
  }

  .article__header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 670px;
    max-width: 100%;
    min-height: 224px;
    flex-shrink: 0;
    background: $color-white;
    padding-top: 60px;
    padding-right: 20px;
    color: $color-black;
    z-index: 1;

    @include target('md', 'xxl') {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .article__title {
      font-family: $font-Tiempos;
      font-size: 36px;
      font-style: normal;
      font-weight: $fw-medium;
      line-height: 112%;

      @include target('xs', 'sm') {
        font-size: 26px;
        font-style: normal;
        font-weight: $fw-medium;
        line-height: 1.07;
      }
    }

    .article__author {
      font-family: $font-Tiempos;
      font-size: 18px;
      font-style: normal;
      font-weight: $fw-semi-bold;
      line-height: 1.22;

      @include target('xs', 'sm') {
        margin-top: 13px;
        font-size: 16px;
        font-style: normal;
        font-weight: $fw-semi-bold;
        line-height: 1.25;
      }
    }

    .article__date {
      color: $color-grey-dark;
      font-family: $font-SuisseIntl;
      font-size: 14px;
      font-style: normal;
      font-weight: $fw-medium;
      line-height: 1.28;

      @include target(xs, lg) {
        margin-top: 4px;
      }
    }
  }

  .article__banner {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    max-width: 1015px;
    padding: 0px 16px 0 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    max-width: 100%;
    aspect-ratio: 203 / 106;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .article__content {
    max-width: 670px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    color: $color-black;

    font-family: $font-Tiempos;
    font-size: 18px;
    font-style: normal;
    font-weight: $fw-regular;
    line-height: 1.22;

    &.article__content--no-mt {
      margin-top: 0;

      .tags {
        margin-top: 0;
      }
    }

    figure,
    .cta-banner {
      margin-top: 2.5rem;
      margin-bottom: 3.75rem;
    }

    .aligned-figure {
      &.aligned-figure--center {
        text-align: center;
      }

      &.aligned-figure--right {
        text-align: right;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    @include target('xs', 'sm') {
      padding-left: 20px;
      padding-right: 20px;
      color: $color-black;

      font-size: 1rem;
      font-style: normal;
      font-weight: $fw-regular;
      line-height: 1.25em;
    }
  }
}


.related-articles__header {
  color: $color-black;
  font-size: 36px;
  font-style: normal;
  font-weight: $fw-medium;
  line-height: 108%;
  margin-bottom: 30px;

  @include target(xs, lg) {
    font-size: 16px;
    font-style: normal;
    font-weight: $fw-medium;
    line-height: 1.25;
    margin-bottom: 15px;
  }

  @include target(sm, lg) {
    font-size: 20px;
  }
}

.related-articles__container {
  max-width: $container-width;
  width: 100%;
  margin: 0 auto;

  @include target('xs') {
    padding: 0 1.25rem 4rem;
  }

  @include target('sm', 'xxl') {
    padding: 0 2.5rem;
  }

  .blog__container {
    padding: 0;
    padding-bottom: 10rem;
  }
}