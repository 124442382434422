@use 'libs/styles/fonts';
@use 'app/styles/config';
@use 'libs/styles/responsive';

.blog-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    background-color: config.$color-white;
    transition: transform 0.25s ease-out, box-shadow 0.25s ease-out;

    .nav__toggler {
        display: none;
    }

    &.blog-header--shadowed {
        box-shadow: 0 5px 10px rgba(config.$color-black, 0.15);
    }

    @include responsive.target(xs) {
        position: relative;

        .nav__toggler {
            display: block;
        }
    }

    &.nav--show-mobile {
        @include responsive.target(sm, xxl) {
            width: auto;
            height: auto;
        }
    }

    .container--blog-header {
        max-width: 1440px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 40px;
    }

    &+main.container {
        padding-top: 185px;

        @include responsive.target(xs) {
            padding-top: 0;
        }
    }
}

.blog-header__top {
    position: relative;
    z-index: 1;
    border-bottom: 3px solid config.$color-grey-lighter;

    .blog-header__logo {
        display: none;
        transition: opacity 0.25s ease;
    }

    &>.container--blog-header {
        justify-content: flex-end;
    }


    @include responsive.target(xs) {
        border-bottom: none;
        display: flex;
        width: 100%;

        .blog-header__logo {
            display: block;
            opacity: 1;
        }

        &>.container--blog-header {
            width: 100%;
            justify-content: space-between;
            padding: 20px;
        }

    }
}

.blog-header__links {
    display: flex;
    padding: 20px 0;
    flex-direction: row;

    @include responsive.target(xs) {
        display: none;
    }

    li {
        padding: 10px;

        &:last-child {
            padding-right: 0;
        }
    }

    a {
        text-decoration: none;
        font-weight: fonts.$fw-medium;
        font-size: 16px;
    }
}

.blog-header__links__separator {
    position: relative;
    padding-right: 20px !important;
    margin-right: 8px;

    &::after {
        position: absolute;
        content: " ";
        width: 1px;
        top: -2px;
        bottom: -2px;
        right: 0;
        background-color: config.$color-grey-light;
    }
}

.blog-header__links__cta {
    background-color: config.$color-black;
    color: config.$color-white;
    position: relative;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    transition: background-color 0.25s ease;
    white-space: nowrap;
}

.blog-header__bottom {
    padding: 30px 0;

    .pillow-scroll-list {
        margin: 0 !important;
    }

    @include responsive.target(xs) {
        padding-top: 0;
        padding-bottom: 20px;

        .container--blog-header {
            padding: 0;
        }

        .blog-header__logo {
            display: none;
        }
    }
}

.blog-header__logo {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: baseline;
    text-decoration: none;
    margin-right: 20px;

    .nav--show-mobile & {
        @include responsive.target(xs, sm) {
            opacity: 0;
        }
    }

    @include responsive.target(md, lg) {
        margin-right: 55px;
    }

    @include responsive.target(xl, xxl) {
        margin-right: 120px;

        [lang="fr-ch"] & {
            margin-right: 80px;
        }
    }

    img {
        height: 18px;

        @include responsive.target(xl, xxl) {
            height: 24px;
        }
    }
}

.blog-header__logo__text {
    color: config.$color-grey-medium;
    font-size: 31px;
    font-style: normal;
    font-weight: fonts.$fw-medium;
    line-height: 1;

    @include responsive.target('xs', 'lg') {
        font-size: 25px;
    }
}

.blog-header__mobile {
    display: none;
    flex-direction: column;
    background: config.$color-white;

    @include responsive.target(xs) {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(-100%);
        transition: transform .32s cubic-bezier(.4, 0, .6, 1);

        .nav--show-mobile & {
            transform: translateY(0);
        }
    }

}