@import 'app/styles/config';
@import 'libs/styles/responsive';
@import 'libs/styles/fonts';

.words-group {
  width: 100%;
  column-count: 3;

  @include target('xs', 'sm') {
    column-count: 1;
  }

  .group {
    height: min-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    break-inside: avoid-column;

    .group__title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;

      margin-right: 2.5rem;
      margin-top: 2.5rem;

      border-radius: 50%;

      background-color: $color-grey-lighter;

      p {
        font-weight: $fw-medium;
        font-size: 36px;
        line-height: 1.3;
        color: $color-grey;
      }

      &.group__title--active {
        background-color: $color-black;

        p {
          color: $color-white;
        }
      }
    }

    .word-container {
      height: min-content;
      width: 70%;
      margin-bottom: 2.5rem;
      padding-top: 2.5rem;
      border: none;
      font-size: 1rem;
      line-height: 1.15;

      @include target('xl', 'xxl') {
        font-size: 1.25rem;
      }

      .word {
        font-weight: $fw-medium;

        &:not(:first-child) {
          margin-top: 0.5rem;
        }

        .link {
          text-decoration: none;
        }
      }

      .word__title {
        color: $color-black;
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-thickness: 1px;
        line-height: 1.3;

        &.word__title--active {
          color: $color-red-05;
        }
      }
    }
  }
}