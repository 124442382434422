@use 'libs/styles/responsive';
@import 'app/styles/config';
@import 'libs/styles/fonts';

.section-top {
  margin-top: 16px;
}

.form-container {
  width: 100%;
  grid-area: content;

  &__inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }

  .input-group__label,
  .textarea-group__label {
    font-size: 16px;
    font-weight: $fw-medium;
  }

  .textarea {
    height: 11.25rem;
  }

  .from-container__button {
    margin-top: 3.125rem;
    width: 100%;
  }

  .contact-info {
    margin-top: 60px;

    .link--external {
      font-size: inherit;
    }

    p {
      margin-bottom: 1rem;
      line-height: 1.25;
    }

    @include responsive.target(lg, xxl) {
      margin-top: 80px;

      p {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }

  .success-message-container {
    display: flex;
    align-items: flex-start;
    margin-top: 2.4375rem;

    .success-message-container__text {
      margin-left: 1.4375rem;
    }
  }
}