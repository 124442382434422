@use 'libs/styles/responsive';
@import 'app/styles/config';

.dropdown {
  position: relative;

  &::before {
    position: absolute;
    display: none;
    left: -20px;
    height: 100%;
    width: 3px;
    background-color: $color-black;
    content: " ";
  }

  &.dropdown--open {
    &::before {
      display: block;
    }
  }
}

.dropdown__content {
  display: none;
  flex-direction: column;

  .nav__link {
    margin-top: 20px;
    color: $color-grey;
  }

  .dropdown--open & {
    display: flex;
  }
}


@include responsive.target(xl, xxl) {
  .dropdown {
    position: static;

    &::before {
      display: none;
    }

    .dropdown__content {
      padding-left: 3px;
      padding-top: 2.34375rem;
      display: block;
      position: absolute;
      z-index: 1;
      pointer-events: none;

      .nav__link {
        display: block;
        line-height: 1.25;
        margin-top: 0;
        color: $color-black;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1);
      }
    }

    .dropdown__content:before {
      content: ' ';
      position: absolute;
      display: block;
      top: -20px;
      left: -3px;
      bottom: 0;
      border-left: 3px solid $color-black;
      transform-origin: top;
      transform: scaleY(0);
      transition: 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    }

    &:hover .dropdown__content {
      pointer-events: all;

      &::before {
        transform: scaleY(1);
        transition-delay: 0.2s;
      }

      .nav__link {
        opacity: 1;
        pointer-events: all;
        transition-delay: 0.35s;
      }
    }

    &:hover .nav__button-link {
      color: $color-black !important;
    }
  }
}