@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
  cursor: default;
}

.tag {
  padding: 0.5rem 1rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: $color-grey-lighter;
  display: inline-flex;
  color: $color-black;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: $fw-regular;
  font-family: $font-SuisseIntl;
  line-height: 1.28;
  position: relative;
  white-space: nowrap;

  &.dark {
    color: $color-white;
    background: $color-black;
  }

  .tag__close {
    height: 18px;
  }

  .dark {
    display: block;
  }

  .gray {
    display: none;
  }

  @include target('xs', 'lg') {
    .tag__close {
      width: 36px;
      height: 36px;
      position: absolute;
      right: -48px;

      .dark {
        display: none;
      }

      .gray {
        display: block;
      }
    }
  }
}
