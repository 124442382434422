@import 'app/styles/config';
@import 'libs/styles/responsive';


p.table-legend {
    font-weight: $fw-light;
    font-family: $font-SuisseIntl;
    font-size: inherit !important;
    letter-spacing: 0.26px;
    line-height: 1.14;

    @include target(md, lg) {
        letter-spacing: 0.3px;
        line-height: 1.42;
    }
}

table {
    width: 100%;
    margin-bottom: 30px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: $fw-medium;

    background-color: $color-grey-lighter;
    font-family: $font-SuisseIntl;
    font-size: 15px;
    border: none;
    border-spacing: 20px 0px;
    padding: 20px 0;
    border-collapse: separate;

    .text-plugin-content &,
    .cke_editable & {
        font-family: $font-SuisseIntl !important;
    }

    @include target(xs, sm) {
        font-size: 14px;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px) !important;
        overflow-wrap: anywhere;

        &.blog-table-scrollable {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
            min-width: 100%;
        }

        caption {
            padding: 0 20px;
            margin-top: -20px;
            background: $color-white;
        }
    }

    p,
    .blog-article__container .article__content & p {
        margin: 0 0 20px 0;

        @include target(xs, sm) {
            hyphens: auto;
        }
    }

    tr {
        position: relative;
        border-bottom: none;
        /* fix for ignored position relative */
        transform: scale(1);

        &:after {
            position: absolute;
            left: 20px;
            right: 20px;
            bottom: 0;
            display: block;
            content: " ";
            // border-bottom: 1px solid $color-black;

            @-moz-document url-prefix() {
                left: 0;
                right: 0;
            }
        }

        td:after {
            position: absolute;
            left: 20px;
            right: 20px;
            bottom: 0;
            // transform: translateY(10px);
            display: block;
            content: " ";
            // border-bottom: 1px solid $color-black;
        }

        @include target(xs, sm) {
            &:first-child {
                td {
                    padding-bottom: 20px;
                }
            }

            // td {
            //   padding-top: 0;
            //   padding-bottom: 0;
            // }
        }
    }

    tr {
        border-bottom: 1px solid $color-black;

        td,
        th {
            min-width: 90px;

            // /* Safari 10.1+ */
            // @media not all and (min-resolution:.001dpcm) {
            //   @supports (-webkit-appearance:none) {
            //     border-bottom: 1px solid $color-black;
            //   }
            // }

            border: none;
            border-bottom: 1px solid $color-black;
        }

        th {
            color: $color-black;
            text-align: left;
            padding: 5px 0 12px;

            &.title {
                padding-top: 0;
                font-size: 20px;
                letter-spacing: 0.25px;
                line-height: 28px;
            }

            &:not(:first-child) {
                padding-left: 10px;
            }
        }

        td {
            color: $color-black;
            padding: 4px 0;

            &:not(:first-child) {
                padding-left: 10px;
            }
        }
    }
}

@include target(md) {
    table {
        font-size: 16px;
        letter-spacing: 0.27px;
        line-height: 20px;
        margin-bottom: 20px;

        tr {
            th {
                padding: 2px 0 10px;

                &.tilte {
                    padding-top: 0;
                    font-size: 20px;
                    letter-spacing: 0.25px;
                    line-height: 28px;
                }
            }

            td {
                padding: 10px 0;
            }
        }
    }
}

@include target(lg) {
    table {
        font-size: 18px;
        letter-spacing: 0.3px;
        line-height: 28px;
        margin-bottom: 20px;

        tr {
            th {
                padding: 2px 0 10px;

                &.tilte {
                    padding-top: 0;
                    font-size: 24px;
                    letter-spacing: 0.3px;
                    line-height: 28px;
                }
            }

            td {
                padding: 10px 0;
            }
        }
    }
}