@import 'app/styles/config';
@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.footer {
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  font-family: $font-SuisseIntl;
  font-weight: $fw-regular;
  color: $color-black;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  padding: 3.75rem 2.5rem 2rem;
  gap: 5rem;

  @include target('xs', 'lg') {
    padding: 2.5rem 1.25rem 5rem;
    gap: 2.5rem;
  }

  .footer__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;

    @include target('xs') {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    @include target('sm', 'md') {
      grid-template-columns: repeat(2, 1fr);
    }


    .footer__grid__item {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .footer__grid__title {
        font-size: 1.25rem;
        line-height: 1.625rem;
        font-weight: $fw-medium;
      }

      a {
        text-decoration: none;
        padding: 0.25rem 0;
        border-top: 1px solid currentColor;
        transition: color 0.25s ease;

        &:hover {
          color: $color-grey-dark;
        }
      }
    }
  }

  .footer__langs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;

    @include target('sm', 'md') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include target('xs') {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .footer__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include target('xs', 'lg') {
      align-items: flex-start;
      flex-direction: column;
      gap: 4rem;
    }
  }

  .footer__legals {
    display: flex;
    gap: 1.25rem;
    align-items: flex-end;

    a {
      font-size: 0.875rem;
      color: $color-grey-dark;
      text-decoration: none;
    }

    .footer__legals__links {
      display: flex;
      gap: 1.25rem;
      align-items: flex-end;
      flex-wrap: wrap;

      @include target('xs') {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    @include target('xs', 'lg') {
      order: 2;
    }

    @include target('xs', 'md') {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .footer__logo {
    margin-right: 1.25rem;

    img {
      width: auto;
      height: 1.5rem;
    }
  }

  .footer__certs {
    display: flex;
    gap: 1.25rem;

    @include target('xs') {
      flex-direction: column;
      gap: 2rem;
      width: 100%;
    }

    @include target('xs', 'lg') {
      order: 1;
    }

    .footer__certs__images {
      display: flex;
      flex-direction: column;
      gap: 0;
      flex-grow: 1;
      flex-shrink: 0;

      .footer__certs__images__item {
        width: 50%;
      }

      img {
        width: 160px;
        height: 90px;
        object-fit: contain;
        object-position: left bottom;
      }

      @include target('xs') {
        width: 100%;
        max-width: calc(100vw - 2.5rem);
        justify-content: flex-start;
        flex-direction: row;

        img {
          object-position: left top;
          max-width: 130px;
          max-height: 70px;
        }
      }
    }

    @include target('xs') {
      .footer__certs__standalone {
        width: 50%;
        max-width: 100px;
      }
    }

    img {
      height: 90px;
      width: auto;
      object-fit: contain;
      object-position: center top;

      @include target('xs') {
        width: 100%;
        height: auto;
        max-width: calc(100vw - 2.5rem);
      }
    }
  }
}