@import 'config';
@import 'libs/styles/fonts';

@include generate-font(
  $font-OpenSans,
  $FontPath: '/static/fonts/open-sans/open-sans-v15-latin-',
  $FontStyles: (
    'regular': (),
    'italic': (
      style: $fs-italic,
    ),
    '300': (
      weight: $fw-light,
    ),
    '300italic': (
      style: $fs-italic,
      weight: $fw-light,
    ),
    '600': (
      weight: $fw-semi-bold,
    ),
    '600italic': (
      style: $fs-italic,
      weight: $fw-semi-bold,
    ),
    '700': (
      weight: $fw-bold,
    ),
    '700italic': (
      style: $fs-italic,
      weight: $fw-bold,
    ),
    '800': (
      weight: $fw-extra-bold,
    ),
    '800italic': (
      style: $fs-italic,
      weight: $fw-extra-bold,
    )
  ),
  $FontFormats: ($ft-woff2, $ft-woff, $ft-eot),
  $FontLigatures: True
);

@include generate-font(
  $font-SuisseIntl,
  $FontPath: '/static/fonts/suisse-intl/suisse-intl-',
  $FontStyles: (
    'regular': (
      style: $fs-normal,
      weight: $fw-regular,
    ),
    '500': (
      style: $fs-normal,
      weight: $fw-medium,
    )
  ),
  $FontFormats: ($ft-ttf),
  $FontLigatures: True
);

/*
  For simplicity we have renamed font files:
  Timepos Text (regular)  = Timepos (reqular)
  Tiempos Fine (medium)   = Timepos (medium)
  Tiempos Text (semibold) = Timepos (semibold)
*/

@include generate-font(
  $font-Tiempos,
  $FontPath: '/static/fonts/tiempos/tiempos-',
  $FontStyles: (
    'regular': (
      style: $fs-normal,
      weight: $fw-regular,
    ),
    'medium': (
      style: $fs-normal,
      weight: $fw-medium,
    ),
    'semibold': (
      style: $fs-normal,
      weight: $fw-semi-bold,
    )
  ),
  $FontFormats: ($ft-woff2),
  $FontLigatures: True
);

body {
  @include font($font-OpenSans);
  @include font($font-SuisseIntl);
  @include font($font-Tiempos);
}
