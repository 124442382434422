@use 'app/styles/typography';
@use 'app/styles/config';
@use 'libs/styles/responsive';
@use 'libs/styles/fonts';
@import 'app/styles/typography';


.author-card {
    display: block;
    margin-top: 1rem;
    margin-bottom: 4rem;

    @include responsive.target(xl, xxl) {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        gap: 20px;
        margin-bottom: 8.75rem;
    }

    .blog__container__authors__list & {
        display: flex;
        flex-direction: row;
        margin-top: 0;
        margin-bottom: 0;
        gap: 20px;
    }
}

.author-card__image {
    position: relative;

    picture,
    img {
        display: block;
    }

    img {
        width: 62.68%;
        margin-bottom: 1rem;

        @include responsive.target(xl, xxl) {
            width: 325px;
            margin-bottom: 1rem;
        }

        .blog__container__authors__list & {
            width: 95px;
            aspect-ratio: 1 / 1;
            border-radius: 50px;
            object-fit: cover;
            margin-bottom: 0;
        }
    }
}

.author-card__image__socials {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 0.5rem;

    @include responsive.target(xl, xxl) {
        position: relative;
        top: auto;
        gap: 20px;
        margin-bottom: 0;
    }

    img {
        display: block;
        height: 18px;
        width: auto;
        margin-bottom: 0;

        &.some-icon--email {
            height: 25px;
            transform: translate(0, -4px);
        }
    }

    a {
        transition: filter 0.25s ease;

        &:hover {
            filter: invert(41%) sepia(0%) saturate(8%) hue-rotate(184deg) brightness(90%) contrast(91%);
        }
    }
}

.author-card__text {
    p {
        @extend .text--primary;
        margin-bottom: 1.25rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .blog__container__authors__list & {
        p {
            font-size: 14px;
            font-weight: fonts.$fw-medium;
            line-height: 1.28;
            color: config.$color-grey;
        }
    }
}

.author-card__text__name {
    margin-bottom: 0 !important;
    color: config.$color-black !important;
}

.author-card__text__media {
    margin-top: 2rem;

    @include responsive.target(lg, xxl) {
        margin-top: 60px;
    }
}

.author-card__text__media__title {
    margin-bottom: 0.5rem !important;
    font-size: 1rem;
    font-weight: $fw-medium !important;
    line-height: 1.25;
}

.media-articles-list-outer {
    position: relative;
    margin: 0 -1.25rem;

    &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1.25rem;
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }

    &::after {
        content: " ";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 2.25rem;
        background: linear-gradient(270deg, #FFFFFF 20%, rgba(255, 255, 255, 0) 100%);
    }
}

.media-articles-list {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-y: hidden;
    overflow-x: visible;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 1.25rem;
    padding-bottom: 1rem;
    row-gap: 30px;

    &::-webkit-scrollbar {
        display: none;
        width: 0 !important
    }

    @include responsive.target(xl, xxl) {
        display: grid;
        overflow: hidden;
        grid-template-columns: repeat(2, 1fr);
    }

    @include responsive.target(xxl) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.media-articles-list__item {
    flex-shrink: 0;

    @include target(xs) {
        max-width: 210px;
    }

    &:last-child {
        margin-right: 0;
    }

    p {
        font-family: $font-Tiempos;
        font-weight: $fw-regular;
        line-height: 1.375;
        margin-bottom: 4px;

        @include responsive.target(xl, xxl) {
            font-size: 18px;
            line-height: 1.222;
        }
    }
}