@use 'libs/styles/responsive';

.award-section-title {
    font-weight: 500 !important;
    margin-top: 60px;
}

.award-section-logo-container {
    display: flex;
    flex-direction: row;
    gap: 60px;
    margin-top: 35px;
    flex-wrap: wrap;

    .award-section-logo {
        margin-top: unset;
    }

    @include responsive.target(xs) {
        gap: 35px;
        row-gap: 20px;

        .award-section-logo {
            max-height: 120px;
        }
    }
}

.award-section-logo-container__break {
    @include responsive.target(xs) {
        flex-basis: 100%;
    }

    @include responsive.target(sm, xxl) {
        display: none;
    }
}

.award-section-logo {
    display: block;
    margin-top: 35px;
    max-width: 210px;
    max-height: 150px;

    &.award-section-logo--large {
        width: 320px;
        max-width: 85%;
    }
}