@import 'app/styles/config';
@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.newsletter-container {
  display: block;
  background-color: $color-grey-lighter;
  padding: 1.25rem;
  color: $color-black;
  font-family: $font-SuisseIntl;
  margin: 0 0 6rem;

  &.newsletter-container--green {
    background-color: $color-green-light;

    input {
      background-color: $color-white !important;
    }
  }

  @include target(xl, xxl) {
    display: grid;
    grid-template-columns: 33.82% 66.18%;
    grid-template-areas: '. content';
    padding: 3.75rem 2.5rem;
  }

  .container>.home>& {
    margin: 0 -1.25rem 70px;

    @include target(xl, xxl) {
      margin: 0 -2.5rem 140px;
    }

    @media screen and (min-width: 1441px) {
      margin: 0 calc(0px - ((100vw - 1440px) / 2) - 2.5rem) 140px;

      .newsletter-container__content {
        width: 884px;
        grid-area: unset;
        margin-left: calc(((100vw - 1440px) / 2) + 2rem - 2.5rem + 467px);
      }
    }
  }

  body.blog>main+& {
    @include target(xl, xxl) {
      display: block;

      .newsletter-container__content {
        max-width: 1015px;
        margin-left: calc((100vw - 5rem - 685px) / 2);
      }
    }
  }

  .newsletter-container__content {
    grid-area: content;
  }

  .newsletter-container__heading {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: $fw-medium;
    line-height: 1.3;
    margin-bottom: 14px;

    @include target(xl, xxl) {
      font-size: 36px;
      line-height: 1.08;
    }
  }

  .newsletter-container__text {
    font-size: 1rem;
    font-weight: $fw-regular;
    line-height: 1.25;
    margin-bottom: 2.3125rem;

    @include target(xl, xxl) {
      font-size: 1.25rem;
      font-weight: $fw-medium;
      line-height: 1.3;
    }
  }

  .newsletter-container__email-form {
    @include target(xl, xxl) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    .button {
      margin-top: 1.5rem;

      @include target(xl, xxl) {
        padding: 1.23rem 1.969rem;
        margin-left: 1.25rem;
        white-space: nowrap;
      }
    }

    .input-group {
      width: 100%;
    }

    input {
      background-color: $color-grey-light;
    }

    .input,
    .input::placeholder {
      font-family: $font-SuisseIntl;
      font-weight: $fw-regular;
      line-height: 1.25;
      opacity: 1;
    }

    label {
      font-size: 16px;
      font-style: normal;
      font-weight: $fw-medium;
      line-height: 1.25;
    }
  }
}