@import 'app/styles/config';
@import 'libs/styles/responsive';

.section {
  margin-top: 70px;

  @include target('xl', 'xxl') {
    margin-top: 140px;
  }

  &.section--no-mt {
    margin-top: 0 !important;
  }

  &.section--top {
    margin-top: 30px;

    @include target(xl, xxl) {
      margin-top: 60px;
    }
  }

  &.section--main {
    margin-top: 1.5625rem;

    @include target('xl', 'xxl') {
      margin-top: 3.75rem;
    }

    .section__content {
      margin-top: 0;
    }
  }

  &.section--top-margin {
    @include target('xl', 'xxl') {
      margin-top: 10.1875rem;
    }
  }

  &.section--grey {
    background-color: $color-grey-lighter;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;

    @include target(xl, xxl) {
      margin-left: -2.5rem;
      margin-right: -2.5rem;
      padding: 3.75rem 2.5rem;
    }

    @media screen and (min-width: 1441px) {
      margin-left: calc(0px - ((100vw - 1440px) / 2) - 2.5rem);
      margin-right: calc(0px - ((100vw - 1440px) / 2) - 2.5rem);

      .section__heading {
        margin-left: calc((100vw - 1440px) / 2);
      }

      .section__content {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.section--divider {
    position: relative;

    &::before {
      position: absolute;
      content: " ";
      top: -40px;
      left: -20px;
      right: -20px;
      height: 1px;
      background-color: $color-grey-light;
    }

    @include target(xs, lg) {
      margin-top: 80px;
    }

    @include target('xl', 'xxl') {
      &::before {
        display: none;
      }
    }
  }

  &.section--highlighted {
    padding: 30px 20px;
    margin-left: -20px;
    margin-right: -20px;
    background-color: $color-grey-lighter;

    @include target('xl', 'xxl') {
      margin-left: -40px;
      margin-right: -40px;
      padding: 60px 40px;
    }

    @media screen and (min-width: 1440px) {
      margin-left: calc(-1 * ((100vw - 1440px) / 2) - 40px);
      margin-right: calc(-1 * ((100vw - 1440px) / 2) - 40px);
    }

    [data-color="red"] & {
      background-color: $color-red-light;
    }

    .section__content {
      margin-top: 0;
    }

    .section__heading {
      border-left: none;
      padding-left: 0;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: $fw-medium;
      line-height: 1.3;
      margin-bottom: 14px;

      @include target(xl, xxl) {
        font-size: 36px;
        line-height: 1.08;
      }
    }
  }

  des-portfolio-development {
    display: block;
    margin-top: 15px;

    @media screen and (min-width: 1121px) and (max-width: 1440px) {
      &>.portfolio-development {
        display: grid !important;
        gap: unset !important;
        grid-template-columns: 33.82% 66.18% !important;
      }
    }

    @include target('xl', 'xxl') {
      margin-top: 30px;
    }
  }

  .section__content {
    margin-top: 15px;

    &.section__content--no-header {
      margin-top: 1.25rem !important;
    }

    &.section__content--grey {
      position: relative;

      &::after {
        position: absolute;
        content: " ";
        z-index: -1;
        top: -20px;
        right: -20px;
        bottom: -40px;
        left: -20px;
        margin-left: -10px;
        background-color: rgba($color-grey-lighter, 0.5);

        @include target(xl, xxl) {
          display: none;
        }
      }
    }

    &+.section__content {
      margin-top: 30px;

      &.section__content--grey {
        margin-top: 50px;
      }

      @include target('xl', 'xxl') {
        margin-top: 60px !important;
      }
    }

    @include target('xl', 'xxl') {
      display: grid;
      grid-template-columns: 33.82% 66.18%;
      grid-template-areas: '. content';
      margin-top: 30px;

      &.section__content--no-header {
        margin-top: 2rem !important;
      }

      &.section__content--no-margin {
        margin-top: 0 !important;
      }
    }
  }

  .section__content__inner {
    grid-area: content;
  }

  .section__blocks {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 32px;

    @include target(xl, xxl) {
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 33.82%;
      margin-top: 40px;
      gap: 60px 20px;
    }
  }

  .section__blocks__block {
    img {
      display: block;
      width: 80px;
      margin-bottom: 20px;
    }

    .text--primary+.link {
      display: inline-block;
      margin-top: 8px;
    }

    @include target(xl, xxl) {
      width: calc(50% - 10px);

      .text--medium {
        font-size: 36px;
        line-height: 0.95;
        margin-bottom: 12px;
      }

      .text--primary+.link {
        margin-top: 16px;
      }
    }
  }

  .section__heading {
    border-left: 5px solid $color-black;
    padding-left: 0.6875rem;

    @include target(xs, lg) {
      border-left: none;
      padding-left: 0;
    }

    @include target(xl, xxl) {
      max-width: 40.8125rem;
    }
  }

  .section__heading--full-width {
    max-width: 100%;
  }

  .article {
    &.article--outlined {
      border-left: 3px solid $color-black;
      padding-left: 6px;
    }

    &.article--spaced {
      margin-bottom: 1rem;
    }
  }

  .article__title {
    margin-bottom: 8px;

    @include target(xl, xxl) {
      margin-bottom: 16px;
    }
  }

  .section__buttons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include target('xl', 'xxl') {
      margin-top: 60px;
      flex-direction: row;
      gap: 1.25rem;
    }
  }

  @include target('xl', 'xxl') {

    .article {
      grid-area: content;
    }

    .article--outlined {
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }

    .article__title {
      font-size: 36px;
      line-height: 1;

      &.article__title--no-top-margin {
        margin-top: 0;
      }

      &.article__title--numbered {
        margin-top: 0;
      }

      @include target('xs', 'md') {
        font-weight: $fw-medium;
      }
    }

    .article__number {
      margin-top: 3.75rem;
    }
  }
}