@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.blog__container__authors {
    grid-column: span 1;

    @include responsive.target(sm, lg) {
        grid-column: span 2;
        margin: 30px 0;
    }

    @include responsive.target(xl, xxl) {
        grid-column: span 3;
        margin: 50px 0;
    }
}

.blog__container__authors__title {
    font-size: 20px;
    font-weight: fonts.$fw-medium;
    margin-bottom: 20px;

    @include responsive.target(xl, xxl) {
        margin-bottom: 30px;
    }
}

.blog__container__authors__list {
    display: grid;
    gap: 40px 20px;

    @include responsive.target(xs, sm) {
        gap: 30px;

        .blog__container__authors--limited & {

            .author-card,
            .author-card-link {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: flex;
                }
            }
        }
    }

    @include responsive.target(md, lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include responsive.target(xl) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include responsive.target(xxl) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.blog__container__authors__extender {
    margin-top: 20px;

    @include responsive.target(md, xxl) {
        display: none;
    }
}