@import 'app/styles/config';
@import 'libs/styles/responsive';

.start-calculator {

  .select {
    margin: 40px 0 15px 0;
    display: none;
  }

  @include target(xs, lg) {
    .pillow-scroll-list {
      display: none;
    }

    .select {
      display: flex;
    }
  }

  .input-group:not(.seed-capital-group) {
    max-width: 210px;
  }

  .start-calculator__form {
    display: none;
  }

  .start-calculator-button {
    margin-top: 30px;
  }

  @include target('xl', 'xxl') {
    &.start-calculator--show-buttons {
      padding-left: 33.82%;
    }

    .start-calculator__form {
      display: flex;
      column-gap: 1.25rem;

      .input-group__label {
        white-space: nowrap;
      }

      input {
        font-family: $font-SuisseIntl;
        font-size: 1rem;
      }
    }

    .start-calculator-button {
      display: none;
    }

    .start-parametrized-calculator-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 3.75rem;
      align-self: flex-end;
      align-items: center;
      padding: 0;

      p {
        text-align: left;
        margin-left: 1rem;
        font-size: 16px;
        line-height: 1;
        color: $color-black;
      }

      .arrow-container {
        border-radius: 30px;
        height: 3.75rem;
        width: 3.75rem;
        background-color: $color-black;
        padding: 1.3rem 1.125rem 1.3rem 0.97rem;
      }

      &.disabled {
        .arrow-container {
          background-color: $color-grey-dark;
        }

        color: $color-grey-dark;
      }
    }
  }
}