@import 'app/styles/config';
@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.video-overlay {
  .video-overlay__background {
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    background-color: $color-black;
    opacity: 0.7;
    z-index: 2;
  }

  .video-overlay__close-button {
    position: fixed;
    top: 2.3125rem;
    right: 2rem;
    z-index: 3;

    @include target('xl', 'xxl') {
      right: 4rem;
    }
  }

  .video-overlay__content {
    display: flex;
    background-color: $color-white;
    z-index: 3;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 30%;

    @include target('sm', 'lg') {
      height: 60%;
    }

    @include target('xl', 'xxl') {
      height: 80%;
      max-width: 72.25rem;
      max-height: 40.625rem;
    }
  }
}
