@import 'app/styles/config';
@import 'libs/styles/responsive';

.number-card {
  position: relative;

  &:nth-child(even) {
    .number-card__number {
      @include target('xs', 'sm') {
        right: 0;
      }
    }
  }

  .number-card__number {
    position: absolute;
    top: 0;
    font-size: 169.78px;
    color: $color-green-light;
    line-height: 1;

    [data-color="red"] & {
      color: $color-red-light;
    }
  }

  .number-card__text-wrapper {
    margin-top: 2.1875rem;
    position: relative;
    z-index: 1;
  }

  .number-card__text {
    line-height: 1.25;
  }

  @include target('md', 'xxl') {
    min-height: 12.75rem;

    .number-card__number {
      margin-left: 0;
    }

    .number-card__text {
      font-size: 20px;
      line-height: 1.3;
    }

    .number-card__text-wrapper {
      margin-top: 4.375rem;
    }
  }
}