.embedding-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;

    iframe,
    .twitter-tweet {
        width: 100%;
        max-width: 500px !important;
        min-width: unset !important;
        margin-bottom: 0 !important;
    }
}