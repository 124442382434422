@import 'app/styles/config';
@import 'libs/styles/responsive';

.member-cards {
  display: grid;
  row-gap: 2.5rem;
  grid-area: content;

  @include target('md', 'xxl') {
    grid-template-columns: minmax(17.9375rem, 20.9375rem);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  @include target('xl', 'xxl') {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.25rem;
  }

  @include target('xl', 'xxl') {
    row-gap: 5rem;
  }
}