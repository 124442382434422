@import 'app/styles/config';

.textarea-group {
  .textarea {
    margin-top: 0.5rem;
  }

  .textarea-group__subtitle {
    font-size: 12px;
    line-height: 1;
    margin-top: 0.5rem;

    &.textarea-group__subtitle--error {
      color: $color-red !important;
      line-height: 1.16;
    }
  }
}
