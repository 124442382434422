@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0
}

main.container {
    &.in-overlay {
        @include target('xs', 'sm') {
            padding: 0;
        }
    }
}

.calculator-body {
    .header {
        z-index: 4;
    }
}

@include target(xs) {
    .calculator-body {
        display: block;
        height: 100%;

        main.container {
            width: 100%;
            height: calc(100% - 56px);

            .calculator,
            .calculator__page,
            .calculator__stage {
                width: 100%;
                height: 100%;
            }
        }
    }
}