@import 'app/styles/config';
@import 'libs/styles/fonts';
@import 'libs/styles/responsive';


.pillow-scroll-list {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 0;
    margin: 20px 0;
    background-color: $color-white;

    overflow-x: auto;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    scroll-behavior: smooth;

    @include target(xl, xxl) {
        margin: 60px 0 30px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &.pillow-scroll-list--root {
        margin: 20px -20px;
        padding: 0 20px;

        @include target(xl, xxl) {
            margin: 60px 0 30px;
            padding: 0;
        }
    }

    &.pillow-scroll-list--anlagemodelle {
        @include target(xl, xxl) {
            display: none;
        }
    }

    .pillow-scroll-list__item__link {
        padding: 15px 28px;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 2rem;
        border: none;
        background: $color-grey-lighter;
        display: inline-flex;
        color: $color-black;
        text-decoration: none;
        font-family: inherit;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: $fw-regular;
        cursor: pointer;
        text-wrap: nowrap;
        white-space: nowrap;

        &.pillow-scroll-list__item__link--active {
            color: $color-white;
            background: $color-black;
        }
    }
}