@import 'app/styles/config';
@import 'libs/styles/responsive';

.charts {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.charts__outer {
  position: relative;
  width: 100%;
  display: grid;
  gap: 2rem;
  justify-self: flex-end;
  align-self: flex-end;
  padding-top: calc(48px + 30px);

  @include target('xl', 'xxl') {
    gap: 3.75rem;
    width: 66.18%;
    padding-top: calc(48px + 60px);
  }

  .button-list {
    position: absolute;
    top: 0;
    left: -1.25rem;
    right: -1.25rem;
    padding: 0 1.25rem;
    bottom: auto;
    align-items: flex-start;
  }

  .charts__inner {
    position: relative;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap: 3.75rem;

    @include target(xs, md) {
      padding: 0 10px;
    }

    @include target(md, lg) {
      gap: 1.75rem;
    }

    @include target('md', 'xxl') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .charts__chart {
    width: 100%;
    position: relative;
    inset: 0;
    height: max-content;

    canvas {
      width: 100%;
      max-width: calc(100vw - 2.5rem);
    }

    .charts__text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      span {
        font-size: 1.125rem;
        line-height: 1.625rem;
        font-weight: $fw-medium;
      }

      strong {
        font-size: 4rem;
        line-height: 1em;
        font-weight: $fw-medium;
      }
    }
  }

  .charts__legend {
    display: flex;
    align-items: center;
    gap: 1.875rem;

    @include target('xs', 'sm') {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .charts__legend-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .charts__legend-item__color {
        width: 1rem;
        height: 1rem;
      }

      .charts__legend-item__label {
        font-size: 0.75rem;
        color: $color-black;
      }
    }
  }
}