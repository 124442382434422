@import 'libs/styles/fonts';
@import 'libs/styles/responsive';
@import 'app/styles/typography';
@import 'app/common/list/list';

.article--glossary-word {
  p {
    @extend .text--primary;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  ul,
  ol {
    @extend .list;
    @extend .list--outlined;

    li {
      @extend .list__item;
      @extend .text--primary;

      &::before {
        filter: invert(1);
      }
    }

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  ol {
    list-style: auto;
    margin-left: 1.5em;

    li {
      border-left: none;

      &::before {
        display: none;
      }
    }
  }

  @include target(xl, xxl) {
    margin-bottom: 70px;
  }
}

.glossar-container {
  margin: 0;
  margin-top: 1em !important;

  @include target('xl', 'xxl') {
    margin: 0 auto 140px !important;
  }
}

.glossary-word-image {
  display: block;
  max-width: 100%;
  max-height: 350px;
  margin-top: 1em;
}