@import 'app/styles/config';
@import 'libs/styles/responsive';
@import 'libs/styles/fonts';

._form {
  max-width: $container-width !important;
  padding-top: 0 !important;
  margin: 0 auto !important;

  ._form-content {
    grid-area: content;
  }

  ._form_element {
    font-family: $font-SuisseIntl;
    margin-bottom: 1.875rem !important;

    p {
      font-weight: $fw-medium;
      font-size: 16px !important;
      line-height: 1.25 !important;
      color: $color-black !important;

      @include target(xl, xxl) {
        font-size: 20px !important;
      }
    }

    a {
      font-weight: $fw-medium;
      font-size: inherit !important;
      line-height: inherit !important;
      color: $color-green-dark;
      text-underline-offset: 3px;
    }
  }

  ._form-label {
    font-family: $font-SuisseIntl;
    font-weight: $fw-medium !important;
    margin-bottom: 0.5rem !important;
    font-size: 16px !important;
    line-height: 1.25 !important;
    color: $color-black !important;
  }

  ._field-wrapper {
    input {
      width: 100%;
      height: 3.75rem !important;
      padding: 1.25rem 0.75rem !important;
      box-sizing: border-box;
      border: 0 !important;
      font-size: 16px !important;
      line-height: 1.25 !important;
      border-left: 3px solid $color-black !important;
      background-color: $color-grey-lighter !important;

      &:focus {
        outline: none;
        background-color: $color-white !important;
        box-shadow: inset 0px -1px 0px 0px $color-black;
      }

      &._has_error {
        background-color: $color-red-light !important;
        border-color: $color-red !important;
      }
    }

    ._error {
      position: static !important;
      padding: 0 !important;

      ._error-arrow {
        display: none;
      }
    }
  }

  ._row {
    width: auto !important;
  }

  ._error-inner {
    padding: 0 !important;
    color: $color-red !important;
    line-height: 1.16 !important;
    background-color: transparent !important;
    background-image: none !important;
    font-family: $font-SuisseIntl !important;
    font-size: 0.75rem !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    text-align: left !important;
    font-weight: $fw-regular !important;
    margin-top: 0.5rem;
  }

  ._checkbox-radio {
    margin-bottom: 12px !important;
    display: flex !important;
    flex-direction: column;

    @include target(xl, xxl) {
      margin-bottom: 7px !important;
    }

    label {
      font-family: $font-SuisseIntl;
      width: 100%;
      font-weight: $fw-medium;
      font-size: 16px !important;
      line-height: 1.25 !important;
      color: $color-black;
      padding-left: 35px;

      @include target(xl, xxl) {
        font-size: 20px !important;
      }
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    input+span {
      display: inline-flex;
      align-items: flex-start;
      user-select: none;
    }

    input+span label::before {
      content: '';
      position: absolute;
      left: 0;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
      flex-grow: 0;
      border: 2px solid $color-black;
      margin-right: 15px;
      background-repeat: no-repeat;
      background-position: center center;
      transform: translateY(-1px);
      cursor: pointer;

      @include target(xl, xxl) {
        transform: translateY(2px);
      }
    }

    input:checked+span label::before {
      border-color: black;
      background-image: url('/static/media/checkbox.svg');
    }

    ._error-inner {
      order: 2;
      padding: 0 !important;
      color: $color-red !important;
      line-height: 1.16 !important;
      background-color: transparent !important;
      background-image: none !important;
      font-family: $font-SuisseIntl !important;
      font-size: 0.75rem !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      text-align: left !important;
      font-weight: $fw-regular !important;
    }
  }

  ._field1 {
    ._checkbox-radio {
      ._error-inner {
        position: absolute;
        bottom: -20px;
      }
    }
  }

  #_form_8_submit._submit,
  #_form_3_submit._submit {
    width: 100%;
    font-weight: $fw-regular;
    font-family: $font-SuisseIntl;
    font-size: 16px;
    line-height: 1;
    border-radius: 0 !important;
    border: none !important;
    background: $color-black !important;
  }

  @include target('xl', 'xxl') {
    width: 100%;
    display: grid;
    grid-template-columns: 33.82% 66.18%;
    grid-template-areas: '. content';
  }
}

.newsletter-successful-text {
  margin-top: 9rem;
}